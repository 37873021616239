import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "reactstrap";

import Confirm from "../../Components/Confirm";
import {
  pageWithQuery,
  navigateToAlertSettings,
} from "../../../redux/actions/Page";
import { deleteSensor } from "../../../redux/actions/Sensors";
import { orgIdSelector } from "../../../selectors/orgIdSelector";
import {
  currentSensorSelector,
  sensorIdSelector,
} from "../../../selectors/sensorsSelectors";
import RebuildSensorModal from "./RebuildSensorModal";

import "./SensorActions.scss";

const SensorActions = ({
  dispatchDeleteSensor,
  dispatchNotification,
  orgId,
  personId,
  showRebuildSensor,
  sensorId,
  sensorName,
}) => {
  const [deleting, setDeleting] = useState(false);

  const onCancelDelete = () => {
    setDeleting(false);
  };

  const onConfirmDelete = () => {
    dispatchDeleteSensor({
      orgId,
      personId,
      sensorId,
    });
    setDeleting(false);
  };

  const onDelete = (evt) => {
    evt.stopPropagation();
    setDeleting(true);
  };

  const handleNotificationClick = () => {
    dispatchNotification();
  };

  return (
    <React.Fragment>
      {deleting && (
        <Confirm
          title="Removing sensor"
          body={`Are you sure you want to permanently remove sensor ${sensorName}?`}
          confirmBtnTxt="Remove"
          onCancel={onCancelDelete}
          onConfirm={onConfirmDelete}
          dataCy={"confirmRemoveSensorButton"}
        />
      )}

      <RebuildSensorModal />

      <div className="sensor-actions">
        <Button
          color="primary"
          onClick={handleNotificationClick}
          datacy={"notificationSettingsSensorButton"}
        >
          Notification Settings
        </Button>

        <Button
          color="primary"
          onClick={() => {
            showRebuildSensor();
          }}
          datacy={"rebuildSensorButton"}
        >
          Rebuild Sensor
        </Button>

        <Button color="danger" onClick={onDelete} datacy={"removeSensorButton"}>
          Remove Sensor
        </Button>
      </div>
    </React.Fragment>
  );
};

// PropTypes
SensorActions.propTypes = {
  dispatchDeleteSensor: PropTypes.func.isRequired,
  dispatchNotification: PropTypes.func.isRequired,
  orgId: PropTypes.string,
  personId: PropTypes.string,
  sensorId: PropTypes.string,
  sensorName: PropTypes.string,
  showRebuildSensor: PropTypes.func.isRequired,
};

SensorActions.defaultProps = {
  orgId: "",
  personId: "",
  sensorId: "",
  sensorName: "",
};

// Connect to Redux
const mapStateToProps = (state) => {
  const { settings } = state;

  const { currentUserId: personId } = settings;

  const orgId = orgIdSelector(state);
  const sensorId = sensorIdSelector(state);
  const sensor = currentSensorSelector(state);

  const { name: sensorName = "" } = sensor;

  return {
    personId,
    orgId,
    sensorId,
    sensorName,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchNotification: () => {
    dispatch(navigateToAlertSettings());
  },

  dispatchDeleteSensor: ({ orgId, personId, sensorId }) =>
    dispatch(
      deleteSensor({
        orgId,
        personId,
        sensorId,
      })
    ),

  showRebuildSensor: () => {
    const query = {
      rebuilding: true,
    };
    dispatch(pageWithQuery(query));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SensorActions);
