import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { ErrorBanner } from "../../Components/ErrorBanner";
import { clearUrlQuery as closeModal } from "../../../redux/actions/Page";
import { clearDetailErr, installModule } from "../../../redux/actions/Sensors";
import { orgIdSelector } from "../../../selectors/orgIdSelector";
import {
  sensorIdSelector,
  currentSensorSelector,
} from "../../../selectors/sensorsSelectors";

import { getCleanMeta, getMetaItems, validateMetaData } from "./metaHelpers";
import NewModule from "./NewModule";

import "./SensorModal.scss";

/*
Helpers
*/

const getModuleMetaFromValue = (moduleValue) =>
  moduleValue && moduleValue.module ? moduleValue.module.meta : [];

const onInstall = ({
  dispatchInstallModule,

  metaValues,
  moduleId,

  orgId,
  personId,
  sensorId,

  setFormErrorMessage,
  setLoading,
}) => {
  const metaCurrent = Object.values(metaValues);

  const metaData = getCleanMeta({ metaValues });

  const errMsg = validateMetaData({
    metaCurrent,
    metaData,
    setError: setFormErrorMessage,
  });

  if (errMsg) {
    return;
  }

  const metaItems = Object.keys(metaData).reduce((accum, key) => {
    accum[key] = metaData[key].value;
    return accum;
  }, {});

  const data = {
    module_id: moduleId,
    ...metaItems,
  };

  setLoading(true);

  dispatchInstallModule({
    orgId,
    personId,
    sensorId,
    data,
  });
};

/*
Main component
*/
const NewModuleModal = ({
  addNewModule,
  availableModules,
  dismissRequestError,
  dispatchCloseModal,
  dispatchInstallModule,
  orgId,
  personId,
  requestError,
  sensorId,
  superadmin,
  license,
}) => {
  const [loading, setLoading] = useState(false);
  const [moduleValue, setModuleValue] = useState(null);
  const [formErrorMessage, setFormErrorMessage] = useState("");

  const [metaValues, setMetaValues] = useState(null);

  const [requestErrorMessage, setRequestErrorMessage] = useState("");

  useEffect(() => {
    dismissRequestError();

    setLoading(false);
    setModuleValue(null);
    setFormErrorMessage("");
  }, [addNewModule, dismissRequestError, orgId, sensorId]);

  useEffect(() => {
    setMetaValues(getMetaItems({ meta: getModuleMetaFromValue(moduleValue) }));
    setFormErrorMessage("");
  }, [moduleValue]);

  useEffect(() => {
    const err = requestError || {};
    const { message = "" } = err;

    setRequestErrorMessage(message);

    setLoading(false);
  }, [requestError]);

  const handleChangeModule = (newValue) => {
    setModuleValue(newValue);
  };

  const cancel = () => {
    dismissRequestError();

    dispatchCloseModal();
  };

  const saveChanges = () => {
    if (formErrorMessage) {
      return;
    }

    if (!moduleValue || !moduleValue.value) {
      setFormErrorMessage("Please choose a module");
      return;
    }

    onInstall({
      dispatchInstallModule,

      metaValues,
      moduleId: moduleValue.value,

      orgId,
      personId,
      sensorId,

      setFormErrorMessage,
      setLoading,
    });
  };

  const disabled = loading || !!formErrorMessage;

  return (
    <Modal
      centered
      isOpen={!!addNewModule}
      className="sensor-modal"
      backdrop={null}
      size="lg"
    >
      <ModalHeader className="sensor-modal-header" toggle={cancel}>
        Add New Module
      </ModalHeader>

      <ModalBody className="align-middle rounded sensor-modal-body">
        <ErrorBanner
          message={requestErrorMessage}
          dismiss={dismissRequestError}
        />
        <ErrorBanner
          message={formErrorMessage}
          dismiss={() => {
            setFormErrorMessage("");
          }}
        />

        <NewModule
          availableModules={availableModules}
          metaValues={metaValues}
          setMetaValues={setMetaValues}
          moduleValue={moduleValue}
          handleChangeModule={handleChangeModule}
          setFormErrorMessage={setFormErrorMessage}
          superadmin={superadmin}
          license={license}
        />
      </ModalBody>

      <ModalFooter className="sensor-modal-footer">
        {!loading && (
          <Button
            color="danger"
            onClick={cancel}
            datacy={"addSensorModuleCancelBtn"}
          >
            Cancel
          </Button>
        )}

        <Button
          className="sensor-modal-loading-btn"
          color="primary"
          disabled={disabled}
          onClick={saveChanges}
        >
          {!loading && <span>Install</span>}
          {loading && <span>...</span>}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

// PropTypes
NewModuleModal.propTypes = {
  addNewModule: PropTypes.string,
  availableModules: PropTypes.arrayOf(PropTypes.shape({})),
  dismissRequestError: PropTypes.func.isRequired,
  dispatchCloseModal: PropTypes.func.isRequired,
  dispatchInstallModule: PropTypes.func.isRequired,
  orgId: PropTypes.string,
  personId: PropTypes.string,
  requestError: PropTypes.shape({}),
  sensorId: PropTypes.string,
  superadmin: PropTypes.bool,
};

NewModuleModal.defaultProps = {
  addNewModule: "",
  availableModules: [],
  orgId: "",
  personId: "",
  requestError: {},
  sensorId: "",
  superadmin: false,
};

// Connect to Redux
const mapStateToProps = (state) => {
  const { location, sensors, settings, users } = state;

  const { query = {} } = location;
  const { addNewModule = "" } = query;

  const orgId = orgIdSelector(state);
  const sensorId = sensorIdSelector(state);
  const sensor = currentSensorSelector(state);

  const { sensorDetail = {} } = sensors;
  const { error: requestError } = sensorDetail;

  const { availableModules = [] } = sensor;

  const { currentUserId: personId = "" } = settings;

  const { byId: usersById = {} } = users;
  const currentUser = usersById[personId] || {};
  const { superadmin = false } = currentUser;

  return {
    addNewModule,
    availableModules,
    orgId,
    personId,
    requestError,
    sensorId,
    superadmin,
    license: state.license,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dismissRequestError: () => {
    dispatch(clearDetailErr);
  },

  dispatchCloseModal: () => {
    dispatch(closeModal);
  },

  dispatchInstallModule: ({ orgId, personId, sensorId, data }) =>
    dispatch(
      installModule({
        orgId,
        personId,
        sensorId,
        data,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewModuleModal);
