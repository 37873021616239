import { Divider, TextField, FormControl } from "@mui/material";

import React, { useEffect, useState } from "react";
import { cloudModuleConfigFieldLabels } from "./constants";
import { Root, classes } from "./styles";
import SecretField from "./SecretField";

const areSecretValuesEmpty = (secretValues) => {
  let areSecretValuesEmpty = true;
  for (const value of Object.values(secretValues)) {
    if (value && value !== "") {
      areSecretValuesEmpty = false;
      break;
    }
  }
  return areSecretValuesEmpty;
};
export function ConfigurationForm(props) {
  const ConfigSection = "configuration";
  const SecretSection = "secret";
  const RootSection = "";

  const { model, disabled, onSubmit, schemas, superadmin } = props;

  const [nameValue, setNameValue] = useState(model.integrationName || "");
  const [configValues, setConfigValues] = useState(model.configuration || {});
  const [secretValues, setSecretValues] = useState({});
  const [configFields, setConfigfields] = useState([]);
  const [secretFields, setSecretFields] = useState([]);
  const integrationType = model.integrationType;

  const integrationSchema = schemas.find(
    (schema) => schema.integrationType === integrationType
  );

  const configurationSchema = integrationSchema.configurationSchema;
  const secretSchema = integrationSchema.secretSchema;
  const nameFieldInfo = {
    section: RootSection,
    label: "Cloud Connector Name",
    name: "integrationName",
    editable: true,
    required: true,
    placeholder: "",
    validate: (value) => {
      return (
        isStringLengthValid(value, 255) &&
        isStringCharactersValid(value, /^[A-Za-z0-9 -]+$/)
      );
    },
  };

  useEffect(() => {
    let configFields = [];
    let secretFields = [];

    for (const [prop, propInfo] of Object.entries(
      configurationSchema["properties"] || {}
    )) {
      if (propInfo.type !== "string") {
        console.warn("unimplemented field type", propInfo.type);
        continue;
      }
      const requiredBySchema =
        configurationSchema["required"] &&
        configurationSchema["required"].includes(prop);
      if (
        cloudModuleConfigFieldLabels[integrationType][ConfigSection] &&
        cloudModuleConfigFieldLabels[integrationType][ConfigSection][prop]
      ) {
        configFields = configFields.concat({
          section: ConfigSection,
          label:
            cloudModuleConfigFieldLabels[integrationType][ConfigSection][prop],
          name: prop,
          editable: true,
          required: requiredBySchema,
          placeholder: requiredBySchema ? "(required)" : "(optional)",
          validate: (value) => {
            if (requiredBySchema) {
              return !!value && value !== "";
            }
            return true;
          },
        });
      }
    }

    for (const [prop, propInfo] of Object.entries(secretSchema["properties"])) {
      if (propInfo["anyOf"] && propInfo["anyOf"].length > 0) {
        propInfo.anyOf.forEach((item) => {
          if (item.type !== "null") {
            for (const [key, value] of Object.entries(item)) {
              propInfo[key] = value;
            }
          }
        });
      }

      if (propInfo.type !== "string" && propInfo.type !== "boolean") {
        // boolean now to allow for is_gcc on M365 CC
        console.warn("unimplemented field type", propInfo.type);
        continue;
      }

      // selectedCloudModule has dropDownValues and,
      // more specifically, provides options for this property
      const isSelectField =
        !!props.selectedCloudModule.dropDownValues &&
        !!props.selectedCloudModule.dropDownValues[prop];

      // selectedCloudModule has textArea property and,
      // more specifically, provides boolean for this property
      const isTextAreaField =
        !!props.selectedCloudModule.textArea &&
        !!props.selectedCloudModule.textArea[prop];

      const isLicenseFeatureEnabledField =
        !!props.selectedCloudModule.licenseFeatureEnabledField &&
        !!props.selectedCloudModule.licenseFeatureEnabledField[prop];

      const doesFieldHaveHelperText =
        !!props.selectedCloudModule.helperText &&
        !!props.selectedCloudModule.helperText[prop];

      // the following code is strictly to maintain
      // the order of properties displayed as they
      // are layed out in the constants file
      const secretSectionKeyArray = Object.keys(
        cloudModuleConfigFieldLabels[integrationType][SecretSection]
      );
      const indexOfProp = secretSectionKeyArray.indexOf(prop);

      const requiredBySchema =
        secretSchema["required"] && secretSchema["required"].includes(prop);
      const fieldCurrentlyRequired = !model.id
        ? requiredBySchema
        : areSecretValuesEmpty(secretValues)
        ? false
        : requiredBySchema;

      secretFields[indexOfProp] = {
        section: SecretSection,
        label:
          cloudModuleConfigFieldLabels[integrationType][SecretSection][prop],
        name: prop,
        editable: true,
        required: fieldCurrentlyRequired,
        placeholder: fieldCurrentlyRequired
          ? "(required)"
          : !model.id
          ? "(optional)"
          : "(no change)",
        options: isSelectField
          ? props.selectedCloudModule.dropDownValues[prop]
          : isTextAreaField
          ? props.selectedCloudModule.textArea[prop]
          : null,
        featureFlag: isLicenseFeatureEnabledField
          ? props.selectedCloudModule.licenseFeatureEnabledField[prop]
          : null,
        helperText: doesFieldHaveHelperText
          ? props.selectedCloudModule.helperText[prop]
          : null,
        type: propInfo.type,
        validate: (value, values) => {
          // Secret values for integrations that already exist are allowed
          // to be all empty because secrets are not returned to the frontend.
          let currentlyRequired = requiredBySchema;
          if (!!model.id && areSecretValuesEmpty(values)) {
            currentlyRequired = false;
          }
          if (currentlyRequired) {
            return !!value && value !== "";
          }
          return true;
        },
      };
    }

    setConfigfields(configFields);
    setSecretFields(secretFields);
  }, [configurationSchema, secretSchema]);

  if (!integrationType || integrationType === "") {
    console.error("integration has no type");
    return <div />;
  }

  if (!integrationSchema) {
    console.error(
      "no schema for this integration type was returned from the server",
      integrationType
    );
    return <div />;
  }

  const updateValidation = (newNameValue, newConfigValues, newSecretValues) => {
    let isOk = true;
    isOk = isOk && nameFieldInfo.validate(newNameValue);
    for (const info of configFields) {
      isOk = isOk && info.validate(newConfigValues[info.name]);
    }
    for (const info of secretFields) {
      isOk = isOk && info.validate(newSecretValues[info.name], newSecretValues);
    }
    props.onValidation({ hasValidationError: !isOk });
  };

  const handleChange = (fieldInfo, value) => {
    // State updates occur asynchronously so we need to save our
    // updates so that we can update the model and do validation
    // afterward.
    let newConfigValues = configValues;
    let newSecretValues = secretValues;
    let newNameValue = nameValue;

    if (fieldInfo.section === ConfigSection) {
      newConfigValues = {
        ...configValues,
        [fieldInfo.name]: value,
      };
      setConfigValues(newConfigValues);
    } else if (fieldInfo.section === SecretSection) {
      newSecretValues = {
        ...secretValues,
        [fieldInfo.name]: value,
      };
      setSecretValues(newSecretValues);
    } else if (
      fieldInfo.section === RootSection &&
      fieldInfo.name === nameFieldInfo.name
    ) {
      newNameValue = value;
      setNameValue(newNameValue);
    } else {
      console.error("unexpected section", fieldInfo);
    }

    model.set({ [nameFieldInfo.name]: newNameValue }, false);
    model.set({ configuration: newConfigValues }, false);

    if (areSecretValuesEmpty(newSecretValues)) {
      model.set({ secret: undefined }, false);
    } else {
      model.set({ secret: newSecretValues }, false);
    }

    updateValidation(newNameValue, newConfigValues, newSecretValues);
  };

  const isStringLengthValid = (string, maxLength) => string.length <= maxLength;
  const isStringCharactersValid = (string, regex) => regex.test(string);

  return (
    <Root
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <FormControl component="fieldset" fullWidth={true}>
        <TextField
          autoFocus={true}
          value={nameValue}
          label={nameFieldInfo.label}
          datacy={nameFieldInfo.name}
          onChange={(e) => handleChange(nameFieldInfo, e.target.value)}
          className={classes.field}
          disabled={disabled || !nameFieldInfo.editable}
          key={RootSection + nameFieldInfo.name}
          error={!nameFieldInfo.validate(nameValue)}
          helperText={
            !nameFieldInfo.validate(nameValue)
              ? "must be less than 256 characters and only contain letters, numbers, or dashes"
              : ""
          }
          InputLabelProps={{
            shrink: true,
          }}
        />

        {configFields.map((fieldInfo, index) => (
          <TextField
            datacy={fieldInfo.name}
            value={configValues[fieldInfo.name] || ""}
            placeholder={fieldInfo.placeholder}
            label={fieldInfo.label}
            onChange={(e) => handleChange(fieldInfo, e.target.value)}
            className={classes.field}
            disabled={disabled || !fieldInfo.editable}
            key={ConfigSection + index}
            error={!fieldInfo.validate(configValues[fieldInfo.name])}
            helperText={fieldInfo.helperText}
            InputLabelProps={{
              shrink: true,
            }}
          />
        ))}

        <Divider className={classes.divider} />

        <p className={classes.secretsText}>
          <span className={classes.secretsTitle}>Secrets: </span>
          {model.id
            ? "Leave the following fields blank to leave this connector’s secrets unchanged."
            : "The following entries will be hidden when editing this cloud connector in the future."}
        </p>

        {secretFields.map((fieldInfo, index) => (
          <SecretField
            disabled={disabled}
            fieldInfo={fieldInfo}
            handleChange={handleChange}
            key={SecretSection + index}
            secretValue={secretValues[fieldInfo.name]}
            secretValues={secretValues}
          />
        ))}

        {model.id && superadmin && (
          <div>
            <Divider className={classes.divider} />
            <p className={classes.secretsText}>
              <span className={classes.secretsTitle}>Admin Info: </span>
              This information is only visible to Blumira superadmins.
            </p>
            <p className={classes.secretsText}>
              <span className={classes.secretsTitle}>Connector ID: </span>
              {model.id}
            </p>
          </div>
        )}
      </FormControl>
    </Root>
  );
}
