import { API } from "./API";

const DEFAULT_OPTIONS = {
  baseUrl: "graphql",
};

export const FORCE_DATA_STUBS = false;
export const FORCE_EMPTY_DATA_STUBS = false;

const SAVED_DATA_QUERY = `
    age
    aggregate
    aggregators
    configuration {
      groupBy
      orderBy {
        fieldName
        direction
      }
      limit
      relativeStart
      relativeEnd
      timeStart
      timeEnd
    }
    created
    createdBy
    dataSource
    deleted
    description
    hasAdvancedFilters
    id
    isBasis
    lastOkDuration
    lastProcessEnd
    modified
    modifiedBy
    name
    orgId
    rawAggregate
    rawSearch
    search {
      operator
      opcode
      fieldName
      fieldValue
      fieldType
      children {
        operator
        opcode
        fieldName
        fieldValue
        fieldType
        children {
          operator
          opcode
          fieldName
          fieldValue
          fieldType
        }
      }
    }
    `;

export const generateSearchFilter = ({
  fieldName,
  fieldValue,
  fieldType,
  children,
  operator = "and",
  opcode = "equal",
}) => ({
  fieldName,
  fieldValue:
    typeof fieldValue !== "string" ? JSON.stringify(fieldValue) : fieldValue,
  fieldType,
  children: children ? generateSearchFilter(children) : undefined,
  operator,
  opcode,
});

const generateQuery = (inputQuery) => {
  const query = {
    ...inputQuery,
  };

  if (inputQuery.search) {
    query.search = inputQuery.search.map(generateSearchFilter);
  }

  const cleanedQuery = Object.keys(query).reduce(
    (all, key) =>
      query[key] !== undefined ? { ...all, [key]: query[key] } : all,
    {}
  );

  return { query: cleanedQuery };
};

export class DataQueryApi extends API {
  constructor(options = {}) {
    super({ ...DEFAULT_OPTIONS, ...options });
  }

  /*
   * Example calling getQueryFilterOptions:
   * ```
   * DataQueryApi.getQueryFiledOptions({
   *   orgId: <orgID>,
   *   dataSource: oneOf<['finding', 'bq']>
   * });
   * ```
   *
   * Returns the collection of searchable fields for a given org for a given
   * dataSource. (e.g. For org Blumria, return all fields on the Findings table
   * that can be searched upon.)
   * @param {String} orgId       UUID of org the function will return fields for
   * @param {String} dataSource  Source of data. oneOf<['FINDING', 'BQ']>
   * @returns {Object} field     Array of objects: fields
   *   ex: [{ fields: [{ field: {String}, type: {String}, mode: {String} }]}]
   */
  async getDataFilterOptions({ orgId, dataSource }) {
    const key = `getDataFilterOptions.${orgId}.${dataSource}`;
    // const sessionData = sessionStorage.getItem(key);
    // if (sessionData) {
    //   const parsedSession = JSON.parse(sessionData);
    //   if (new Date().getTime() - parsedSession.created > (1000 * 3600 * 2)) {
    //     sessionStorage.removeItem(sessionData);
    //   } else {
    //     return parsedSession.data;
    //   }
    // }

    const query = `
    query DQ($dataSource: DataSource!, $orgId: ID!) {
      getDataFilterOptions(orgId: $orgId, dataSource: $dataSource) {
        fields {
          field
          type
          mode
        }
      }
    }
    `;

    const data = { orgId, dataSource };

    const { getDataFilterOptions } = await super.get({ query, data });

    const response = {
      fields: getDataFilterOptions.fields,
    };

    sessionStorage.setItem(
      key,
      JSON.stringify({
        created: new Date().getTime(),
        data: response,
      })
    );

    return response;
  }

  async getDataQuery({ orgId, dataSource }) {
    const query = `
    query fetchDataQueries($orgId: ID, $dataSource: String) {
      getDataQuery(orgId: $orgId, dataSource: $dataSource) {
        ${SAVED_DATA_QUERY}
      }
    }`;

    const { getDataQuery } = await super.get({
      query,
      data: { orgId, dataSource },
    });
    return { dataQueries: getDataQuery || [] };
  }

  async runDataQuery(inputQuery) {
    const data = generateQuery(inputQuery);

    const query = `
      query RunDataQuery(
        $query: DataQueryInput!
      ) {
        runDataQuery(query: $query) {
          maxResultsExceeded
          resultFileUrl
          results {
            fieldName
            fieldValue
          }
        }
      }
    `;

    const { runDataQuery } = await super.get({ query, data });

    return runDataQuery;
  }

  async createDataQuery(data) {
    const query = `
      mutation DataQueryInput(
        $aggregate: [String]!
        $aggregators: [String!]
        $configuration: ConfigurationInput!
        $dataSource: String!
        $name: String!
        $search: [SearchFilterInput]
        $orgId: ID!
        $outputType: OutputType
        $selectedQueryId: Int
      ) {
        createDataQuery(
          aggregate: $aggregate,
          aggregators: $aggregators,
          configuration: $configuration,
          dataSource: $dataSource,
          name: $name,
          search: $search,
          orgId: $orgId,
          outputType: $outputType
          selectedQueryId: $selectedQueryId,
        ) {
          ${SAVED_DATA_QUERY}
        }
      }
    `;

    const { createDataQuery: savedQuery } = await super.get({ query, data });

    return { savedQuery };
  }

  // @TODO: should this one query really handle every single data_query call?
  //  Specifically the ones being made over a time period could possibly be
  //  generalized into another query.
  //  Or is it fine and simmpler to just keep it this way even if we send and
  //  get back a bunch of nulls? Definitely makes it simple to just append to
  //  this one call and ignore nulls.
  async processDataQuery({ orgId, personId, dataQueryId, options = {} }) {
    const {
      merge = null,
      timeStart = null,
      timeEnd = null,
      relativeStart = null,
      relativeEnd = null,
    } = options;

    const query = `
      query DataQuery(
        $orgId: ID!,
        $personId: ID!,
        $dataQueryId: ID!,
        $merge: Boolean,
        $timeStart: String,
        $timeEnd: String,
        $relativeStart: String,
        $relativeEnd: String
      ) {
        processDataQuery(
          orgId: $orgId,
          personId: $personId,
          dataQueryId: $dataQueryId,
          merge: $merge,
          timeStart: $timeStart,
          timeEnd: $timeEnd,
          relativeStart: $relativeStart,
          relativeEnd: $relativeEnd
        ) {
          type
          count
          bigCount
          priority
          status
          jsonb_array_elements_src_country
          m_avg_seconds_to_status
          created
          id
          name
          resolution
          seconds_to_status
          summary
        }
      }
    `;

    const data = {
      orgId,
      personId,
      dataQueryId,
      merge,
      timeStart,
      timeEnd,
      relativeStart,
      relativeEnd,
    };

    const resp = await super.get({ query, data });

    const processDataQueryData = resp ? resp.processDataQuery : [];

    return { data: processDataQueryData };
  }

  // Fetches the parsing error counts for sensor sensorId
  async fetchSupportInfo({ orgId, sensorId, relativeStart }) {
    const query = `
      query fetchSupportInfo(
        $orgId: ID!
        $sensorId: ID!
        $relativeStart: String
      ) {
        supportInfo(
          orgId: $orgId
          sensorId: $sensorId
          relativeStart: $relativeStart
        ) {
          latestParsedTime
          latestTimestamp
          parsingErrors {
            count
            type
          }
        }
      }
    `;

    const data = {
      orgId,
      sensorId,
      relativeStart,
    };

    const respData = await super.get({ query, data });

    return { data: respData.supportInfo };
  }

  // Fetches the list of devices for sensor sensorId
  async listSensorDevices({ orgId, sensorId, relativeStart }) {
    const query = `
      query listSensorDevices(
        $orgId: ID!
        $sensorId: ID!
        $relativeStart: String
      ) {
        devices(
          orgId: $orgId
          sensorId: $sensorId
          relativeStart: $relativeStart
        ) {
          devicesArr {
            count
            deviceAddress
            name
            type
          }
          timeWindow {
            windowEnd
            windowStart
          }
        }
      }
    `;

    const data = {
      orgId,
      sensorId,
      relativeStart,
    };
    const resp = await super.get({ query, data });

    const { devices: devicesAll } = resp;

    const {
      devicesArr: devices = [],
      timeWindow: { windowEnd, windowStart },
    } = devicesAll;

    return {
      data: {
        devices,
        windowEnd,
        windowStart,
      },
    };
  }

  async fetchQueryDescriptions() {
    const query = `
      query fetchQueryDescriptions {
        queryDescriptions {
          description
          name,
          title,
        }
      }
    `;

    const resp = await super.get({ query, data: {} });

    const queryDescriptionsData = resp ? resp.queryDescriptions : [];

    return { data: queryDescriptionsData };
  }

  async fetchAttackTypes({ orgId, queryParams }) {
    const query = `
      query fetchAttackTypes(
        $orgId: ID!
        $queryParams: queryParams
      ) {
        attackTypes(
          orgId: $orgId
          queryParams: $queryParams
        ) {
          count
          vulnName
        }
      }
    `;

    const data = {
      orgId,
      queryParams,
    };

    const resp = await super.get({ query, data });

    const attackTypesData = resp ? resp.attackTypes : [];

    return { data: attackTypesData };
  }

  async fetchBlockedAttacks({ orgId, queryParams }) {
    const query = `
      query fetchBlockedAttacks(
        $orgId: ID!
        $queryParams: queryParams
      ) {
        blockedAttacks(
          orgId: $orgId
          queryParams: $queryParams
        ) {
          count
          vulnName
        }
      }
    `;

    const data = {
      orgId,
      queryParams,
    };

    const resp = await super.get({ query, data });

    const blockedAttacksData = resp ? resp.blockedAttacks : [];

    return { data: blockedAttacksData };
  }

  async fetchEndpointAttacks({ orgId, queryParams }) {
    const query = `
      query fetchEndpointAttacks(
        $orgId: ID!
        $queryParams: queryParams
      ) {
        endpointAttacks(
          orgId: $orgId
          queryParams: $queryParams
        ) {
          count
          vulnName
        }
      }
    `;

    const data = {
      orgId,
      queryParams,
    };

    const resp = await super.get({ query, data });

    const endpointAttacksData = resp ? resp.endpointAttacks : [];

    return { data: endpointAttacksData };
  }

  async fetchEndpointEvents({ orgId, queryParams }) {
    const query = `
      query fetchEndpointEvents(
        $orgId: ID!
        $queryParams: queryParams
      ) {
        endpointEvents(
          orgId: $orgId
          queryParams: $queryParams
        ) {
          count
          devname
        }
      }
    `;

    const data = {
      orgId,
      queryParams,
    };

    const resp = await super.get({ query, data });

    const endpointEventsData = resp ? resp.endpointEvents : [];

    return { data: endpointEventsData };
  }

  async fetchEndpointHosts({ orgId, queryParams }) {
    const query = `
      query fetchEndpointHosts(
        $orgId: ID!
        $queryParams: queryParams
      ) {
        endpointHosts(
          orgId: $orgId
          queryParams: $queryParams
        ) {
          deviceAddress
          devname
          totalBytes
        }
      }
    `;

    const data = {
      orgId,
      queryParams,
    };

    const resp = await super.get({ query, data });

    const endpointHostsData = resp ? resp.endpointHosts : [];

    return { data: endpointHostsData };
  }

  async fetchEndpointVictims({ orgId, queryParams }) {
    const query = `
      query fetchEndpointVictims(
        $orgId: ID!
        $queryParams: queryParams
      ) {
        endpointVictims(
          orgId: $orgId
          queryParams: $queryParams
        ) {
          count
          victim
        }
      }
    `;

    const data = {
      orgId,
      queryParams,
    };

    const resp = await super.get({ query, data });

    const endpointVictimsData = resp ? resp.endpointVictims : [];

    return { data: endpointVictimsData };
  }

  async fetchEgressTraffic({ orgId, queryParams }) {
    const query = `
      query fetchEgressTraffic(
        $orgId: ID!
        $queryParams: queryParams
      ) {
        egressTraffic(
          orgId: $orgId
          queryParams: $queryParams
        ) {
          totalBytes
          country
        }
      }
    `;

    const data = {
      orgId,
      queryParams,
    };

    const resp = await super.get({ query, data });

    const egressTrafficData = resp ? resp.egressTraffic : [];

    return { data: egressTrafficData };
  }

  async fetchFailedLogins({ orgId, queryParams }) {
    const query = `
      query fetchFailedLogins(
        $orgId: ID!
        $queryParams: queryParams
      ) {
        failedLogins(
          orgId: $orgId
          queryParams: $queryParams
        ) {
          count
          user
        }
      }
    `;

    const data = {
      orgId,
      queryParams,
    };

    const resp = await super.get({ query, data });

    const failedLoginsData = resp ? resp.failedLogins : [];

    return { data: failedLoginsData };
  }

  async fetchFindings({ orgId, queryParams }) {
    const query = `
      query fetchFindings(
        $orgId: ID!
        $queryParams: queryParams
      ) {
        findings(
          orgId: $orgId
          queryParams: $queryParams
        ) {
          count
        }
      }
    `;

    const data = {
      orgId,
      queryParams,
    };

    const resp = await super.get({ query, data });

    const findingsData = resp ? resp.findings : {};

    return { data: findingsData };
  }

  async fetchIngressTraffic({ orgId, queryParams }) {
    const query = `
      query fetchIngressTraffic(
        $orgId: ID!
        $queryParams: queryParams
      ) {
        ingressTraffic(
          orgId: $orgId
          queryParams: $queryParams
        ) {
          totalBytes
          country
        }
      }
    `;

    const data = {
      orgId,
      queryParams,
    };

    const resp = await super.get({ query, data });

    const ingressTrafficData = resp ? resp.ingressTraffic : [];

    return { data: ingressTrafficData };
  }

  async fetchIntruders({ orgId, queryParams }) {
    const query = `
      query fetchIntruders(
        $orgId: ID!
        $queryParams: queryParams
      ) {
        intruders(
          orgId: $orgId
          queryParams: $queryParams
        ) {
          count
          srcIp
        }
      }
    `;

    const data = {
      orgId,
      queryParams,
    };

    const resp = await super.get({ query, data });

    const intrudersData = resp ? resp.intruders : [];

    return { data: intrudersData };
  }

  // Fetches the list of devices for org orgId
  async fetchLoggingDevices({ orgId, queryParams, limit }) {
    const query = `
      query fetchLoggingDevices(
        $orgId: ID!
        $queryParams: queryParams
        $limit: Int
      ) {
        loggingDevices(
          orgId: $orgId
          queryParams: $queryParams
          limit: $limit
        ) {
          count
          deviceAddress
          name
        }
      }
    `;

    const data = {
      orgId,
      queryParams,
      limit,
    };

    const resp = await super.get({ query, data });

    const loggingDevicesData = resp ? resp.loggingDevices : [];

    return { data: loggingDevicesData };
  }

  // Fetches the list of devices for org orgId
  async fetchLoggingEndpoints({ orgId, queryParams, limit }) {
    const query = `
      query fetchLoggingEndpoints(
        $orgId: ID!
        $queryParams: queryParams
        $limit: Int
      ) {
        loggingEndpoints(
          orgId: $orgId
          queryParams: $queryParams
          limit: $limit
        ) {
          deviceAddress
          count
          devname
        }
      }
    `;

    const data = {
      orgId,
      queryParams,
      limit,
    };

    const resp = await super.get({ query, data });

    const loggingEndpointsData = resp ? resp.loggingEndpoints : [];

    return { data: loggingEndpointsData };
  }

  // Fetches the total number of logs for the org
  async fetchTotalLogs({ orgId, queryParams }) {
    const query = `
      query fetchTotalLogs(
        $orgId: ID!
        $queryParams: queryParams
      ) {
        logsTotal(
          orgId: $orgId
          queryParams: $queryParams
        ) {
          count
          windowStart
          windowEnd
        }
      }
    `;

    const data = {
      orgId,
      queryParams,
    };

    const resp = await super.get({ query, data });

    const logsTotalData = resp ? resp.logsTotal : {};

    return { data: logsTotalData };
  }

  // Fetches the number of blocked events for the org
  async fetchBlockedEvents({ orgId, queryParams }) {
    const query = `
      query fetchBlockedEvents(
        $orgId: ID!
        $queryParams: queryParams
      ) {
        blockedEvents(
          orgId: $orgId
          queryParams: $queryParams
        ) {
          count
        }
      }
    `;

    const data = {
      orgId,
      queryParams,
    };

    const resp = await super.get({ query, data });

    const blockedEventsData = resp ? resp.blockedEvents : {};

    return { data: blockedEventsData };
  }

  // Fetches the number of findings at level Risk or higher
  async fetchThreatsAndSuspects({ orgId, queryParams }) {
    const query = `
      query fetchThreatsAndSuspects(
        $orgId: ID!
        $queryParams: queryParams
      ) {
        threatsAndSuspects(
          orgId: $orgId
          queryParams: $queryParams
        ) {
          count
        }
      }
    `;

    const data = {
      orgId,
      queryParams,
    };

    const resp = await super.get({ query, data });

    const threatsAndSuspectsData = resp ? resp.threatsAndSuspects : {};

    return { data: threatsAndSuspectsData };
  }

  // Fetches the number of findings at level Risk or higher
  async fetchVictims({ orgId, queryParams }) {
    const query = `
      query fetchVictims(
        $orgId: ID!
        $queryParams: queryParams
      ) {
        victims(
          orgId: $orgId
          queryParams: $queryParams
        ) {
          count
          dstIp
        }
      }
    `;

    const data = {
      orgId,
      queryParams,
    };

    const resp = await super.get({ query, data });

    const victimsData = resp ? resp.victims : [];

    return { data: victimsData };
  }
}
