import { styled } from "@mui/material/styles";
import { Dialog } from "@mui/material";

const PREFIX = "IntegrationDialog";
export const classes = {
  moduleOptionsContainer: `${PREFIX}-moduleOptionsContainer`,
  moduleButton: `${PREFIX}-moduleButton`,
  moduleOptionContainer: `${PREFIX}-moduleOptionContainer`,
  textContainer: `${PREFIX}-textContainer`,
  moduleTitleText: `${PREFIX}-moduleTitleText`,
  moduleSubtitleText: `${PREFIX}-moduleSubtitleText`,
  form: `${PREFIX}-form`,
  dialogActionsBtnContainer: `${PREFIX}-dialogActionsBtnContainer`,
  alert: `${PREFIX}-alert`,
  errorTextButton: `${PREFIX}-errorTextButton`,
  errorContainedButton: `${PREFIX}-errorContainedButton`,
  container: `${PREFIX}-container`,
  logoCtaContainer: `${PREFIX}-logoCtaContainer`,
  logoContainer: `${PREFIX}-logoContainer`,
  paidPlanCtaContainer: `${PREFIX}-paidPlanCtaContainer`,
  nextStepContainer: `${PREFIX}-nextStepContainer`,
  nextStepCtaContainer: `${PREFIX}-nextStepCtaContainer`,
  nextStepCtaButton: `${PREFIX}-nextStepCtaButton`,
  nextStepText: `${PREFIX}-nextStepText`,
  nextStepOrText: `${PREFIX}-nextStepOrText`,
  nextStepLink: `${PREFIX}-nextStepLink`,
  buttonsGrid: `${PREFIX}-buttonsGrid`,
  tooltip: `${PREFIX}-tooltip`,
  betaTooltipCtaContainer: `${PREFIX}-betaTooltipCtaContainer`,
  accordionCard: `${PREFIX}-accordionCard`,
  accordionContainer: `${PREFIX}-accordionContainer`,
  accordionTitle: `${PREFIX}-accordionTitle`,
  accordionSummaryDetails: `${PREFIX}-accordionSummaryDetails`,
  stepOneAccordionText: `${PREFIX}-stepOneAccordionText`,
  titleWrap: `${PREFIX}-titleWrap`,
  informationalTooltip: `${PREFIX}-informationalTooltip`,
  informationalTooltipText: `${PREFIX}-informationalTooltipText`,
  outlinedHeaderLink: `${PREFIX}-outlinedHeaderLink`,
  informationalTooltipClose: `${PREFIX}-informationalTooltipClose`,
  accordionOneChip: `${PREFIX}-accordionOneChip`,
  accordionTwoSummary: `${PREFIX}-accordionTwoSummary`,
  accordionTwoNotMappedChip: `${PREFIX}-accordionTwoNotMappedChip`,
  accordionTwoMappedChip: `${PREFIX}-accordionTwoMappedChip`,
  accordionOneNotConnectedChip: `${PREFIX}-accordionOneNotConnectedChip`,
  accordionOneConnectedChip: `${PREFIX}-accordionOneConnectedChip`,
  modalTitleWrap: `${PREFIX}-modalTitleWrap`,
  setupGuideLink: `${PREFIX}-setupGuideLink`,
  accordionDetailsContainer: `${PREFIX}-accordionDetailsContainer`,
  accordionOneTextField: `${PREFIX}-accordionOneTextField`,
  accordionOneButton: `${PREFIX}-accordionOneButton`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  successText: `${PREFIX}-successText`,
  successContainer: `${PREFIX}-successContainer`,
  integrationMappingButton: `${PREFIX}-integrationMappingButton`,
  accordionThreeSelect: `${PREFIX}-accordionThreeSelect`,
  accordionThreeButton: `${PREFIX}-accordionThreeButton`,
  accordionFiveSection: `${PREFIX}-accordionFiveSection`,
  accordionFiveImage: `${PREFIX}-accordionFiveImage`,
  accordionFiveStepOne: `${PREFIX}-accordionFiveStepOne`,
  accordionFiveImageWrap: `${PREFIX}-accordionFiveImageWrap`,
  tableCellOne: `${PREFIX}-tableCellOne`,
  tableCellTwo: `${PREFIX}-tableCellTwo`,
  sendFindingsButton: `${PREFIX}-sendFindingsButton`,
  accordionFiveFigure: `${PREFIX}-accordionFiveFigure`,
  accordionFiveCaption: `${PREFIX}-accordionFiveCaption`,
  accordionFiveToggle: `${PREFIX}-accordionFiveToggle`,
  toggleContainer: `${PREFIX}-toggleContainer`,
  successTextGreen: `${PREFIX}-successTextGreen`,
  successTextRed: `${PREFIX}-successTextRed`,
  dialogActions: `${PREFIX}-dialogActions`,
};

const chip = {
  padding: "0 10px",
  borderRadius: 20,
  display: "inline-flex",
  marginLeft: 8,
  minHeight: "100%",
  minWidth: 8,
};

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.moduleOptionsContainer}`]: {
    display: "flex",
    paddingBottom: 20,
  },
  [`& .${classes.moduleButton}`]: {
    margin: 5,
    width: "100%",
    padding: "0 !important",
    border: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.primary,
  },
  [`& .${classes.moduleOptionContainer}`]: {
    height: 150,
    width: "100%",
    display: "flex",
    cursor: "pointer",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: theme.shape.borderRadius,
    textAlign: "left",
  },
  [`& .${classes.textContainer}`]: {
    display: "flex",
    flexWrap: "no-wrap",
    flexDirection: "column",
    padding: theme.shape.padding,
  },
  [`& .${classes.moduleTitleText}`]: {
    margin: 0,
  },
  [`& .${classes.moduleSubtitleText}`]: {
    margin: 0,
    fontWeight: "bold",
  },
  [`& .${classes.form}`]: {
    width: 600,
    paddingTop: 10,
  },
  [`& .${classes.dialogActionsBtnContainer}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.alert}`]: {
    margin: "10px 0",
    backgroundColor: "#D9EBFF",
  },
  [`& .${classes.errorTextButton}`]: {
    color: theme.palette.error.main,
  },
  [`& .${classes.errorContainedButton}`]: {
    backgroundColor: theme.palette.error.main,
  },
  [`& .${classes.container}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.logoCtaContainer}`]: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  [`& .${classes.logoContainer}`]: {
    padding: `${theme.shape.padding}px 0 0 ${theme.shape.padding}px`,
  },
  [`& .${classes.paidPlanCtaContainer}`]: {
    fontSize: 12,
    padding: `5px 10px `,
    backgroundColor: theme.palette.info.secondary,
    borderRadius: `0 ${theme.shape.borderRadius}px 0 ${theme.shape.borderRadius}px`,
    position: "absolute",
    top: 21,
    right: -5,
    "& p": {
      marginBottom: 0,
      fontWeight: "bold",
      color: theme.palette.grey[900],
    },
    "& a": {
      color: theme.palette.primary.main,
      "& :hover": {
        textDecoration: "underline",
      },
    },
    "& svg": {
      paddingLeft: 5,
      color: theme.palette.primary.main,
    },
  },
  [`& .${classes.betaTooltipCtaContainer}`]: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
  },
  [`& .${classes.tooltip}`]: {
    boxShadow: theme.shadows[4],
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${classes.nextStepContainer}`]: {},
  [`& .${classes.nextStepCtaContainer}`]: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `${theme.shape.padding}px 0`,
  },
  [`& .${classes.nextStepCtaButton}`]: {
    height: 60,
    width: "48%",
    fontSize: 15,
  },
  [`& .${classes.nextStepText}`]: {
    textAlign: "center",
  },
  [`& .${classes.nextStepOrText}`]: {
    fontWeight: "bold",
    textAlign: "center",
    paddingTop: theme.shape.padding,
  },
  [`& .${classes.nextStepLink}`]: {
    fontSize: 18,
    display: "flex",
    justifyContent: "center",
    color: theme.palette.primary.main,
    paddingBottom: theme.shape.padding,
    "& :hover": {
      textDecoration: "underline",
    },
  },
  [`& .${classes.buttonsGrid}`]: {
    position: "relative",
  },

  [`& .${classes.accordionCard}`]: {
    boxShadow: "none",
    margin: `${theme.shape.padding}px 0`,
    border: `1px solid ${theme.palette.border.xdrTrialCard}`,
  },
  [`& .${classes.accordionContainer}`]: {
    padding: "5px 0px",
  },
  [`& .${classes.accordionTitle}`]: {
    fontWeight: "bold",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  [`& .${classes.accordionSummaryDetails}`]: {
    display: "flex",
    fontSize: 12,
    fontWeight: 400,
    gap: "12px",
  },
  [`& .${classes.stepOneAccordionText}`]: {
    width: "fit-content",
    display: "inline-block",
    marginRight: "10px",
  },
  [`& .${classes.titleWrap}`]: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative",
  },
  [`& .${classes.accordionOneNotConnectedChip}`]: {
    backgroundColor: "#FBDBB4",
    color: "#A84700",
    fontWeight: "normal",
    fontSize: 14,
    padding: "0 10px",
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    "& svg": {
      fontSize: 14,
      marginRight: 5,
    },
  },
  [`& .${classes.accordionTwoSummary}`]: {
    display: "flex",
  },
  [`& .${classes.accordionTwoNotMappedChip}`]: {
    ...chip,
    backgroundColor: "#FBDBB4",
    color: "#A84700",
  },
  [`& .${classes.accordionTwoMappedChip}`]: {
    ...chip,
    backgroundColor: "#EDEDED",
    color: "#3B3B3B",
  },
  [`& .${classes.accordionOneConnectedChip}`]: {
    backgroundColor: "#C6E9D6",
    color: "#213C2E",
    fontWeight: "normal",
    fontSize: 14,
    padding: "0 10px",
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    "& svg": {
      fontSize: 14,
      marginRight: 5,
    },
  },
  [`& .${classes.modalTitleWrap}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  [`& .${classes.setupGuideLink}`]: {
    fontSize: 14,
    display: "flex",
    borderRadius: 10,
    cursor: "pointer",
    padding: "0px 10px",
    alignItems: "center",
    color: "#3C73EB",
    backgroundColor: "#D9E3F7",
    width: "fit-content",
    marginBottom: 15,
    "& svg": {
      fontSize: 16,
      paddingLeft: 5,
    },
  },
  [`& .${classes.accordionDetailsContainer}`]: {
    display: "flex",
    flexDirection: "column",
  },
  [`& .${classes.accordionOneTextField}`]: {
    width: "50%",
    marginBottom: 15,
  },
  [`& .${classes.accordionOneButton}`]: {
    width: "fit-content",
    marginRight: 20,
  },
  [`& .${classes.buttonContainer}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.successText}`]: {
    margin: "0 0 0 10px",
    fontSize: "14px",
  },
  [`& .${classes.successContainer}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.integrationMappingButton}`]: {
    marginTop: 24,
  },
  [`& .${classes.accordionThreeSelect}`]: {
    margin: "8px 8px 20px 0",
    width: "33%",
  },
  [`& .${classes.accordionThreeButton}`]: {
    width: "fit-content",
  },
  [`& .${classes.accordionFiveSection}`]: {
    width: "50%",
  },
  [`& .${classes.accordionFiveFigure}`]: {
    width: "70%",
  },
  [`& .${classes.accordionFiveImage}`]: {
    width: "100%",
    height: "auto",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  },
  [`& .${classes.accordionFiveCaption}`]: {
    textAlign: "center",
    margin: "10px 0",
    fontSize: "0.875rem",
  },
  [`& .${classes.accordionFiveStepOne}`]: {
    display: "flex",
  },
  [`& .${classes.accordionFiveImageWrap}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  [`& .${classes.tableCellOne}`]: {
    width: 150,
    fontWeight: "700",
    fontSize: "0.875rem",
    paddingBottom: 10,
    "&:last-of-type": {
      verticalAlign: "top",
    },
  },
  [`& .${classes.lastTableCellOne}`]: {
    verticalAlign: "top",
  },
  [`& .${classes.tableCellTwo}`]: {
    minWidth: 200,
    paddingBottom: 10,
    fontSize: "0.875rem",
    "& .MuiOutlinedInput-root": {
      minWidth: "100%",
    },
  },
  [`& .${classes.sendFindingsButton}`]: {
    borderColor: theme.palette.text.primary,
    color: theme.palette.text.primary,
  },
  [`& .${classes.accordionFiveToggle}`]: {
    display: "block",
    width: "fit-content",
    marginBottom: 0,
    marginRight: 30,
  },
  [`& .${classes.toggleContainer}`]: {
    marginBottom: 20,
  },
  [`& .${classes.successTextGreen}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.successTextRed}`]: {
    color: theme.palette.error.main,
  },
  [`& .${classes.dialogActions}`]: {
    justifyContent: "flex-start",
    padding: "20px",
  },
}));
