import React from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";

import { get } from "lodash";

import HelpIcon from "@mui/icons-material/HelpOutline";

const PREFIX = "Zendesk";
const classes = {
  button: `${PREFIX}-button`,
  openButton: `${PREFIX}-openButton`,
  closedButton: `${PREFIX}-closedButton`,
  openIcon: `${PREFIX}-openIcon`,
  helpContainer: `${PREFIX}-helpContainer`,
  openHelpContainer: `${PREFIX}-openHelpContainer`,
  closedHelpContainer: `${PREFIX}-closedHelpContainer`,
  openPlanText: `${PREFIX}-openPlanText`,
  closedPlanText: `${PREFIX}-closedPlanText`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.button}`]: {
    height: 45,
    color: "white",
    display: "flex",
    fontSize: "1rem",
    fontWeight: "700",
    alignItems: "center",
    borderRadius: "999rem",
    backgroundColor: "#3E7EF8",
    "&:hover": {
      color: "white",
      textDecoration: "none",
    },
  },
  [`& .${classes.openButton}`]: {
    width: "fit-content",
    padding: "1rem",
    justifyContent: "space-around",
  },
  [`& .${classes.closedButton}`]: {
    width: 45,
    justifyContent: "center",
  },
  [`& .${classes.openIcon}`]: {
    marginRight: 5,
  },
  [`& .${classes.helpContainer}`]: {
    position: "fixed",
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
    left: 0,
    zIndex: 22000,
    transition: theme.transitions.create("width", {
      easing: "cubic-bezier(0.4, 0, 0.6, 1)",
      duration: 225,
    }),
  },
  [`& .${classes.openHelpContainer}`]: {
    padding: 20,
    whiteSpace: "normal",
    width: 219,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  [`& .${classes.closedHelpContainer}`]: {
    padding: 10,
    width: 63,
  },
  [`& .${classes.openPlanText}`]: {
    opacity: 1,
    transition: theme.transitions.create("opacity", {
      easing: "cubic-bezier(0.4, 0, 0.6, 1)",
      duration: 225,
      delay: 100,
    }),
  },
  [`& .${classes.closedPlanText}`]: {
    opacity: 0,
    transition: theme.transitions.create("opacity", {
      easing: "cubic-bezier(0.4, 0, 0.6, 1)",
      duration: 175,
    }),
  },
}));

const Zendesk = (props) => {
  const { toplevel, secondlevel } = props;

  let helpDeskUrl;
  let helpButtonText = "Help";

  if (
    secondlevel === "mspintegrations" &&
    props.currentOrg?.config?.license_features?.includes("MSPPSA")
  ) {
    helpDeskUrl =
      "https://blumirabeta.zendesk.com/hc/en-us/articles/36167170934035-Integrating-with-ConnectWise-PSA";
    helpButtonText = "Beta feedback";
  } else if (toplevel === "mspportal") {
    helpDeskUrl = "https://blumira.help/partners";
  } else {
    helpDeskUrl = "https://blumira.help/help";
  }

  const renderPlanText = () => {
    if (props.isAdministeringOrg) {
      return "";
    } else if (props.licenseLabel.length) {
      return `${props.licenseLabel} edition`;
    } else {
      return "";
    }
  };

  return (
    <Root>
      {props.open ? (
        <div
          className={`${classes.helpContainer} ${classes.openHelpContainer}`}
        >
          <p className={classes.openPlanText}>{renderPlanText()}</p>
          <a
            className={`${classes.button} ${classes.openButton}`}
            href={helpDeskUrl}
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            <HelpIcon className={classes.openIcon} />
            {helpButtonText}
          </a>
        </div>
      ) : (
        <div
          className={`${classes.helpContainer} ${classes.closedHelpContainer}`}
        >
          <p className={classes.closedPlanText}>{renderPlanText()}</p>
          <a
            className={`${classes.button} ${classes.closedButton}`}
            href={helpDeskUrl}
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            <HelpIcon />
          </a>
        </div>
      )}
    </Root>
  );
};

const mapStateToProps = (state) => {
  if (!state?.session?.settings?.user) return {};

  const toplevel = state.location.payload.toplevel;
  const secondlevel = state.location.payload.secondlevel;

  const currentOrgId = get(state, ["page", "payload", "orgId"], null);
  const currentOrg = state.session.settings.userOrgs.find(
    (o) => o["id"] === currentOrgId
  );

  return {
    toplevel: toplevel,
    currentOrgId: currentOrgId,
    currentOrg: currentOrg,
    secondlevel: secondlevel,
  };
};

export default connect(mapStateToProps)(Zendesk);
