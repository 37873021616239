export const IntegrationTypeAWS = "aws/Initialize";
export const IntegrationTypeDuo = "duo/poll";
export const IntegrationTypeO365 = "o365/Initialize";
export const IntegrationTypeAzHub = "azhub/poll";
export const IntegrationTypeSentinelOne = "sentinelone/poll";
export const IntegrationTypeMimecast = "mimecast/poll";
export const IntegrationTypeWebroot = "webroot/poll";
export const IntegrationTypeUmbrella = "umbrella/poll";
export const IntegrationTypeGoogle = "gsuite/poll";
export const IntegrationTypeGCP = "gcp/poll";
export const IntegrationTypeSophos = "sophos/poll";
export const IntegrationTypeCarbonBlack = "cb/poll";
export const IntegrationTypeJumpCloud = "jumpcloud/poll";
export const IntegrationTypeOneLogin = "onelogin/poll";
export const IntegrationTypeOnePass = "onepass/poll";
export const IntegrationTypeCrowdstrike = "crowdstrike/poll";
export const IntegrationTypeMscloud = "mscloud/poll";

export const INTEGRATION_STATUS = {
  INIT: "initializing",
  OK: "ok",
  ERROR: "error",
  DELETED: "deleted",
};

export const cloudModuleMetadata = [
  {
    title: "Amazon Web Services",
    subtitle: "Kinesis",
    imageWidth: 60,
    setupGuideLink: "https://blumira.help/aws",
    description: "",
    integrationType: IntegrationTypeAWS,
  },
  {
    title: "Duo Security",
    subtitle: "Authentication and endpoint",
    imageWidth: 70,
    setupGuideLink: "https://blumira.help/duo",
    description:
      "This cloud connector fetches events from the Duo Security Admin API. There are prerequisites that need to be completed in the Duo Admin Panel before continuing. Please open our setup guide in another browser window, then click Next below to get started.",
    integrationType: IntegrationTypeDuo,
  },
  {
    title: "Microsoft 365",
    subtitle: "Security event logs and alerts",
    setupGuideLink: "https://blumira.help/m365",
    description:
      "This cloud connector ingests audit logs from Microsoft 365. There are prerequisites that need to be completed in the Microsoft Admin Center before continuing. Please open our setup guide in another browser window, then click Next below to get started.",
    integrationType: IntegrationTypeO365,
    helperText: {
      is_gcc:
        "By checking this box you acknowledge the activation of data collection from the configured cloud connector.",
    },
  },
  {
    title: "Microsoft Azure Event Hubs",
    subtitle: "Event logs and Alerts",
    imageWidth: 100,
    setupGuideLink: "https://blumira.help/azure",
    description:
      "This cloud connector is a real-time logging and data ingestion service with integration across the Microsoft Azure platform.",
    integrationType: IntegrationTypeAzHub,
  },
  {
    title: "SentinelOne",
    subtitle: "Alerts, Activities, and Threats",
    setupGuideLink: "https://blumira.help/sentinelone",
    description:
      "This cloud connector ingests Alerts and Activities logs from SentinelOne. There are prerequisites that need to be completed in SentinelOne before continuing. Please open our setup guide in another browser window, then click Next below to get started.",
    integrationType: IntegrationTypeSentinelOne,
  },
  {
    title: "Mimecast",
    subtitle: "Audit, Release, Rejection, and SIEM",
    setupGuideLink: "https://blumira.help/mimecast",
    description:
      "This cloud connector ingests audit, release, rejection, and SIEM logs from Mimecast. There are prerequisites that need to be completed in the Mimecasst Administration Console before continuing. Please open our setup guide in another browser window, then click Next below to get started.",
    integrationType: IntegrationTypeMimecast,
    dropDownValues: {
      management_url: [
        {
          label: "AU - https://au-api.mimecast.com",
          value: "https://au-api.mimecast.com",
        },
        {
          label: "CA - https://ca-api.mimecast.com",
          value: "https://ca-api.mimecast.com",
        },
        {
          label: "DE - https://de-api.mimecast.com",
          value: "https://de-api.mimecast.com",
        },
        {
          label: "EU - https://eu-api.mimecast.com",
          value: "https://eu-api.mimecast.com",
        },
        {
          label: "Offshore - https://je-api.mimecast.com",
          value: "https://je-api.mimecast.com",
        },
        {
          label: "US - https://us-api.mimecast.com",
          value: "https://us-api.mimecast.com",
        },
        {
          label: "USB - https://usb-api.mimecast.com",
          value: "https://usb-api.mimecast.com",
        },
        {
          label: "USPCOM - https://uspcom-api.mimecast-pscom-us.com",
          value: "https://uspcom-api.mimecast-pscom-us.com",
        },
        {
          label: "ZA - https://za-api.mimecast.com",
          value: "https://za-api.mimecast.com",
        },
      ],
    },
  },
  {
    title: "Webroot",
    subtitle: "Threat and URL Blocking",
    setupGuideLink: "https://blumira.help/webroot",
    description:
      "This cloud connector monitors Threats and URL Blocking from Webroot. There are prerequisites that need to be completed in Webroot before continuing. Please open our setup guide in another browser window, then click Next below to get started.",
    integrationType: IntegrationTypeWebroot,
  },
  {
    title: "Umbrella",
    subtitle: "Activity event logs",
    imageWidth: 120,
    setupGuideLink: "https://blumira.help/umbrella",
    description: "",
    integrationType: IntegrationTypeUmbrella,
    dropDownValues: {
      loc: [
        {
          label: "US",
          value: "US",
        },
        {
          label: "EU",
          value: "EU",
        },
      ],
    },
  },
  {
    title: "Google Workspace",
    subtitle: "Google Apps Report API",
    imageWidth: 110,
    setupGuideLink: "https://blumira.help/googleworkspaces",
    description: "",
    integrationType: IntegrationTypeGoogle,
    textArea: {
      admin_email: false,
      json_data: true,
    },
  },
  {
    title: "Google Cloud Platform",
    subtitle: "GCP Audit Logs",
    imageWidth: 110,
    description: "",
    integrationType: IntegrationTypeGCP,
    textArea: {
      project_name: false,
      json_data: true,
    },
  },
  {
    title: "Carbon Black",
    subtitle: "Endpoint, EDR",
    imageWidth: 110,
    description: "",
    integrationType: IntegrationTypeCarbonBlack,
    setupGuideLink: "https://blumira.help/CarbonBlackAC",
  },
  {
    title: "Sophos Central",
    subtitle: "SIEM Events",
    imageWidth: 110,
    description: "",
    setupGuideLink: "https://blumira.help/sophos",
    integrationType: IntegrationTypeSophos,
  },
  {
    title: "JumpCloud",
    subtitle: "Event Logging",
    imageWidth: 120,
    description: "",
    integrationType: IntegrationTypeJumpCloud,
    setupGuideLink: "https://blumira.help/jumpcloud",
  },
  {
    title: "OneLogin",
    subtitle: "Event Broadcaster",
    imageWidth: 120,
    description: "",
    integrationType: IntegrationTypeOneLogin,
    setupGuideLink: "https://blumira.help/onelogin",
  },
  {
    title: "1Password",
    subtitle: "Audit, Usage, Sign In Attempts",
    setupGuideLink: "https://blumira.help/1password",
    description:
      "Audit Events, Sign in attempts, and Item Usage will provide a full scope of events occurring within the 1Password environment",
    integrationType: IntegrationTypeOnePass,
    imageWidth: 120,
    dropDownValues: {
      url: [
        {
          label: "Business - https://events.1password.com",
          value: "https://events.1password.com",
        },
        {
          label: "CA - https://events.1password.ca",
          value: "https://events.1password.ca",
        },
        {
          label: "EU - https://events.1password.eu",
          value: "https://events.1password.eu",
        },
        {
          label: "Enterprise - https://events.ent.1password.com",
          value: "https://events.ent.1password.com",
        },
      ],
    },
  },
  {
    title: "Crowdstrike",
    subtitle: "Alerts",
    setupGuideLink: "https://blumira.help/crowdstrike",
    description: "Alerts generated by Crowdstrike",
    integrationType: IntegrationTypeCrowdstrike,
    dropDownValues: {
      client_cloud: [
        {
          label: "api.crowdstrike.com",
          value: "us-1",
        },
        {
          label: "api.us-2.crowdstrike.com",
          value: "us-2",
        },
        {
          label: "api.eu-1.crowdstrike.com",
          value: "eu-1",
        },
        {
          label: "api.laggar.gcw.crowdstrike.com",
          value: "us-gov-1",
        },
        {
          label: "api.us-gov-2.crowdstrike.mil",
          value: "gov2",
        },
      ],
    },
    imageWidth: 120,
  },
  {
    title: "MS Defender for Cloud Apps",
    subtitle: "Alerts and Activities",
    setupGuideLink: "https://blumira.help/cloudapps",
    description:
      "Alerts and Activities generated by MS Defender for Cloud Apps",
    integrationType: IntegrationTypeMscloud,
    imageWidth: 120,
  },
];
