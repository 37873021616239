import PropTypes from "prop-types";
import React from "react";
import { Button, Modal } from "react-bootstrap";

const Confirm = (props) => {
  const {
    title,
    body,
    cancelBtnTxt,
    confirmBtnTxt,
    confirmBtnColor,
    onCancel,
    onConfirm,
    dataCy,
  } = props;

  return (
    <div className="static-modal">
      <Modal.Dialog style={{ zIndex: 1500 }} datacy={"confirmModal"}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>{body}</Modal.Body>

        <Modal.Footer>
          <Button onClick={onCancel}>{cancelBtnTxt}</Button>
          {onConfirm && (
            <Button
              bsStyle={confirmBtnColor}
              onClick={onConfirm}
              datacy={dataCy ? dataCy : ""}
            >
              {confirmBtnTxt}
            </Button>
          )}
        </Modal.Footer>
      </Modal.Dialog>
    </div>
  );
};

Confirm.propTypes = {
  body: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  cancelBtnTxt: PropTypes.string,
  confirmBtnColor: PropTypes.string,
  confirmBtnTxt: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
};

Confirm.defaultProps = {
  body: null,
  cancelBtnTxt: "Cancel",
  confirmBtnColor: "danger",
  confirmBtnTxt: "Delete",
  onCancel: null,
  onConfirm: null,
  title: null,
};

export default Confirm;
