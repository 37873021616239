import {
  Select,
  Checkbox,
  MenuItem,
  TextField,
  FormGroup,
  InputLabel,
  FormControl,
  InputAdornment,
  FormControlLabel,
} from "@mui/material";

import PropTypes from "prop-types";
import React, { useState } from "react";
import RemoveRedEye from "@mui/icons-material/RemoveRedEye";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { classes } from "./styles";

const SecretField = (props) => {
  const { disabled, fieldInfo, handleChange, secretValue, secretValues } =
    props;
  const [isInputMasked, setIsInputMasked] = useState(true);

  const toggleMask = () => setIsInputMasked((show) => !show);

  // display checkbox for boolean field types like is_gcc
  if (fieldInfo.type === "boolean") {
    return (
      <FormGroup>
        <FormControlLabel
          control={<Checkbox />}
          label={fieldInfo.label}
          checked={secretValue}
          onChange={(e) => {
            handleChange(fieldInfo, e.target.checked);
          }}
        />
        {fieldInfo.helperText && (
          <p className={classes.checkboxHelperText}>{fieldInfo.helperText}</p>
        )}
      </FormGroup>
    );
  } else if (Array.isArray(fieldInfo.options) && !!fieldInfo.options.length) {
    // optional options array prop for Select
    // see CloudModules/constants for example
    return (
      <FormControl fullWidth>
        <InputLabel id={"select-field-label"}>{fieldInfo.label}</InputLabel>
        <Select
          label={fieldInfo.label}
          className={classes.field}
          labelId={"select-field-label"}
          value={secretValue || ""}
          disabled={disabled || !fieldInfo.editable}
          onChange={(e) => handleChange(fieldInfo, e.target.value)}
          datacy={"integrationSelectField"}
          InputLabelProps={{
            shrink: true,
          }}
        >
          {fieldInfo.options.map((o, index) => (
            <MenuItem
              key={o.label}
              value={o.value}
              datacy={`integrationSelectOption-${index}`}
            >
              {o.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else if (fieldInfo.options === true) {
    // optional options boolean prop for string fields with multiple lines
    // see CloudModules/constants for example (ie gsuite connector)
    return (
      <TextField
        inputProps={
          isInputMasked
            ? {
                style: {
                  fontFamily: "text-security-disc",
                },
              }
            : {}
        }
        InputProps={{
          endAdornment: (
            <InputAdornment
              style={{ cursor: "pointer" }}
              position={"end"}
              onClick={toggleMask}
            >
              {isInputMasked ? <RemoveRedEye /> : <VisibilityOff />}
            </InputAdornment>
          ),
        }}
        label={fieldInfo.label}
        datacy={fieldInfo.name}
        className={classes.field}
        placeholder={fieldInfo.placeholder}
        value={secretValue || ""}
        disabled={disabled || !fieldInfo.editable}
        onChange={(e) => handleChange(fieldInfo, e.target.value)}
        error={!fieldInfo.validate(secretValue, secretValues)}
        multiline
        minRows={8}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  } else {
    return (
      <TextField
        inputProps={
          isInputMasked
            ? {
                style: {
                  fontFamily: "text-security-disc",
                },
              }
            : {}
        }
        InputProps={{
          endAdornment: (
            <InputAdornment
              style={{ cursor: "pointer" }}
              position={"end"}
              onClick={toggleMask}
            >
              {isInputMasked ? <RemoveRedEye /> : <VisibilityOff />}
            </InputAdornment>
          ),
        }}
        label={fieldInfo.label}
        datacy={fieldInfo.name}
        className={classes.field}
        placeholder={fieldInfo.placeholder}
        value={secretValue || ""}
        disabled={disabled || !fieldInfo.editable}
        onChange={(e) => handleChange(fieldInfo, e.target.value)}
        error={!fieldInfo.validate(secretValue, secretValues)}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }
};

SecretField.propTypes = {
  disabled: PropTypes.bool.isRequired,
  fieldInfo: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  secretValue: PropTypes.string,
  secretValues: PropTypes.object.isRequired,
};

export default SecretField;
