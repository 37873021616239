import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { ErrorBanner } from "../../Components/ErrorBanner";
import NewSensor from "./NewSensor";
import { clearDetailErr, createSensor } from "../../../redux/actions/Sensors";
import { clearUrlQuery as closeModal } from "../../../redux/actions/Page";
import { fetchLocationsForOrg } from "../../../redux/actions/Locations";
import { isEmpty } from "./moduleHelpers";
import { currentOrgSensorsSelector } from "../../../selectors/sensorsSelectors";
import { orgIdSelector } from "../../../selectors/orgIdSelector";

import "./SensorModal.scss";

import { Dialog } from "@mui/material";

/*
Helpers
*/

const validateSetError = ({ fieldName, value, setError }) => {
  const valid = !isEmpty(value);

  if (!valid) {
    setError(`Invalid ${fieldName}`);
  } else {
    setError("");
  }

  return valid;
};

const SENSOR_NAME_REGEX = /^[a-zA-Z0-9_.'-]+$/;

const validateNameSetError = ({
  orgSensors,
  setFormErrorMessage,
  submitting = false,
  value,
}) => {
  // No name
  if (isEmpty(value)) {
    setFormErrorMessage("Sensor name cannot be empty");

    return false;
  }

  const valid = SENSOR_NAME_REGEX.test(value);

  // Invalid name
  if (!valid) {
    setFormErrorMessage("Invalid sensor name");

    return false;
  }

  // Name already exists
  if (submitting) {
    const nameCollision = orgSensors.some((sensor) => sensor.name === value);

    if (nameCollision) {
      setFormErrorMessage(
        "Duplicate sensor name." +
          ` A sensor named ${value} already exists in your organization.`
      );
      return false;
    }
  }

  setFormErrorMessage("");

  return true;
};

const validateLocationSetError = (value, setError) => {
  const { value: valueProp = "" } = value || {};

  return validateSetError({
    fieldName: "sensor location",
    value: valueProp,
    setError,
  });
};

/*
Main component
*/
const NewSensorModal = ({
  dispatchCloseModal,
  addNewSensor,
  dismissRequestError,
  dispatchCreateSensor,
  fetchLocations,
  locations,
  orgId,
  orgSensors,
  personId,
  requestError,
}) => {
  const [description, setDescription] = useState("");
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [locationValue, setLocationValue] = useState({});
  const [nameValue, setNameValue] = useState("");
  const [noBroadcast, setNoBroadcast] = useState(false);

  const [requestErrorMessage, setRequestErrorMessage] = useState("");

  useEffect(() => {
    dismissRequestError();

    fetchLocations(orgId);

    setLoading(false);
    setLocationValue({});
    setNameValue("");
    setFormErrorMessage("");
  }, [addNewSensor, dismissRequestError, fetchLocations, orgId]);

  useEffect(() => {
    const err = requestError || {};
    const { message = "" } = err;

    setRequestErrorMessage(message);

    setLoading(false);
  }, [requestError]);

  const handleChangeBroadcast = () => {
    setNoBroadcast(!noBroadcast);
  };

  const handleChangeDescription = (evt) => {
    const { target = {} } = evt;
    const { value = "" } = target;

    setDescription(value);
  };

  const handleChangeLocation = (newValue) => {
    setLocationValue(newValue);
    validateLocationSetError(newValue, setFormErrorMessage);
  };

  const handleChangeName = (evt) => {
    const { target = {} } = evt;
    const { value = "" } = target;

    setNameValue(value);
    validateNameSetError({
      orgSensors,
      setFormErrorMessage,
      value,
    });
  };

  const validate = () =>
    validateLocationSetError(locationValue, setFormErrorMessage) &&
    validateNameSetError({
      orgSensors,
      setFormErrorMessage,
      submitting: true,
      value: nameValue,
    });

  const cancel = () => {
    dismissRequestError();

    dispatchCloseModal();
  };

  const saveChanges = () => {
    if (formErrorMessage) {
      return;
    }

    const valid = validate();

    if (!valid) {
      return;
    }

    const data = {
      description,
      configInstall: { onlyEmailCreator: noBroadcast },
      locationId: locationValue.value,
      name: nameValue,
      orgId,
    };

    setLoading(true);

    dispatchCreateSensor({
      orgId,
      personId,
      data,
    });
  };

  const disabled = loading || !!formErrorMessage;

  return (
    <Dialog
      open={!!addNewSensor}
      onClose={cancel}
      className={"sensor-modal"}
      fullWidth
      maxWidth={"sm"}
      datacy={"addNewSensorModal"}
    >
      <ModalHeader className="sensor-modal-header" toggle={cancel}>
        Add New Sensor
      </ModalHeader>

      <ModalBody className="align-middle rounded sensor-modal-body">
        <ErrorBanner
          message={requestErrorMessage}
          dismiss={dismissRequestError}
        />
        <ErrorBanner
          message={formErrorMessage}
          dismiss={() => {
            setFormErrorMessage("");
          }}
        />

        <NewSensor
          description={description}
          handleChangeBroadcast={handleChangeBroadcast}
          handleChangeDescription={handleChangeDescription}
          handleChangeName={handleChangeName}
          handleChangeLocation={handleChangeLocation}
          loading={loading}
          locations={locations}
          locationValue={locationValue}
          nameValue={nameValue}
          noBroadcast={noBroadcast}
        />
      </ModalBody>

      <ModalFooter className="sensor-modal-footer">
        {!loading && (
          <Button color="danger" onClick={cancel}>
            Cancel
          </Button>
        )}

        <Button
          className="sensor-modal-loading-btn"
          color="primary"
          disabled={disabled}
          onClick={saveChanges}
          datacy={"newSensorInstallBtn"}
        >
          {!loading && <span>Install</span>}
          {loading && <span>...</span>}
        </Button>
      </ModalFooter>
    </Dialog>
  );
};

// PropTypes
NewSensorModal.propTypes = {
  dispatchCloseModal: PropTypes.func.isRequired,
  addNewSensor: PropTypes.string,
  dismissRequestError: PropTypes.func.isRequired,
  dispatchCreateSensor: PropTypes.func.isRequired,
  fetchLocations: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  orgId: PropTypes.string,
  orgSensors: PropTypes.arrayOf(PropTypes.shape({})),
  personId: PropTypes.string,
  requestError: PropTypes.shape({}),
};

NewSensorModal.defaultProps = {
  addNewSensor: "",
  locations: [],
  orgId: "",
  orgSensors: [],
  personId: "",
  requestError: {},
};

// Connect to Redux
const mapStateToProps = (state) => {
  const { location, locations, sensors, settings } = state;

  const { sensorDetail = {} } = sensors;

  const { error: requestError } = sensorDetail;

  const { query = {} } = location;
  const { addNewSensor } = query;

  const { currentUserId: personId = "" } = settings;
  const { locations: orgLocations = [] } = locations;

  const orgId = orgIdSelector(state);
  const orgSensors = currentOrgSensorsSelector(state);

  return {
    addNewSensor,
    locations: orgLocations,
    orgId,
    orgSensors,
    personId,
    requestError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchCloseModal: () => {
    dispatch(closeModal);
  },

  dismissRequestError: () => {
    dispatch(clearDetailErr);
  },

  dispatchCreateSensor: ({ data, orgId, personId }) =>
    dispatch(createSensor({ orgId, personId, data })),

  fetchLocations: (orgId) => {
    dispatch(fetchLocationsForOrg(orgId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewSensorModal);
