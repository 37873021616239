import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import clsx from "clsx";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import CardActionArea from "@mui/material/CardActionArea";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import LinearProgress from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import Chip from "@mui/material/Chip";

import MessageList from "./MessageList";

import { Message as MessageActions } from "../../../../redux/actions";

import { sanitize } from "../../../../lib/helpers";
import { setOwners } from "../../../../redux/actions/Findings";
import moment from "moment-timezone";

import { FLAG_TYPE, PRIORITY } from "../../../../design/colors";

const PREFIX = "FindingCard";
const cardClasses = {
  root: `${PREFIX}-root`,
  mainContent: `${PREFIX}-mainContent`,
  analysisBody: `${PREFIX}-analysisBody`,
  expand: `${PREFIX}-expand`,
  cluster: `${PREFIX}-cluster`,
  clusterAvatarItem: `${PREFIX}-clusterAvatarItem`,
  clusterAvatar: `${PREFIX}-clusterAvatar`,
  expandOpen: `${PREFIX}-expandOpen`,
  flagType10: `${PREFIX}-flagType10`,
  flagType15: `${PREFIX}-flagType15`,
  flagType20: `${PREFIX}-flagType20`,
  flagType30: `${PREFIX}-flagType30`,
  flagType40: `${PREFIX}-flagType40`,
  priority1: `${PREFIX}-priority1`,
  priority2: `${PREFIX}-priority2`,
  priority3: `${PREFIX}-priority3`,
};

const popoverClasses = {
  userMenuContainer: `${PREFIX}-userMenuContainer`,
  userMenu: `${PREFIX}-userMenu`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${cardClasses.root}`]: {
    marginBottom: theme.spacing(2),
    overflowWrap: "break-word",
  },
  [`& .${cardClasses.mainContent}`]: {
    paddingTop: 0,
  },
  [`& .${cardClasses.analysisBody}`]: {
    marginBottom: "0.35em",
  },
  [`& .${cardClasses.expand}`]: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  [`& .${cardClasses.cluster}`]: {
    "&:hover *:not(:first-of-type)": {
      marginLeft: theme.spacing(0),
    },
    "& > *:not(:first-of-type)": {
      marginLeft: theme.spacing(-4),
    },
  },
  [`& .${cardClasses.clusterAvatarItem}`]: {
    transition: theme.transitions.create(["margin-left"], {
      duration: theme.transitions.duration.standard,
    }),
  },
  [`& .${cardClasses.clusterAvatar}`]: {
    border: "2px solid white",
  },
  [`& .${cardClasses.expandOpen}`]: {
    transform: "rotate(180deg)",
  },
  [`& .${cardClasses.flagType10}`]: {
    color: FLAG_TYPE["10"],
    fontWeight: 800,
  },
  [`& .${cardClasses.flagType15}`]: {
    color: FLAG_TYPE["15"],
    fontWeight: 800,
  },
  [`& .${cardClasses.flagType20}`]: {
    color: FLAG_TYPE["20"],
    fontWeight: 800,
  },
  [`& .${cardClasses.flagType30}`]: {
    color: FLAG_TYPE["30"],
    fontWeight: 800,
  },
  [`& .${cardClasses.flagType40}`]: {
    color: FLAG_TYPE["40"],
    fontWeight: 800,
  },
  [`& .${cardClasses.priority1}`]: {
    backgroundColor: PRIORITY["1"],
  },
  [`& .${cardClasses.priority2}`]: {
    backgroundColor: PRIORITY["2"],
  },
  [`& .${cardClasses.priority3}`]: {
    backgroundColor: PRIORITY["3"],
  },
}));

const StyledPopover = styled(Popover)(({ theme }) => ({
  [`& .${popoverClasses.userMenuContainer}`]: {
    zIndex: theme.zIndex.modal,
    position: "absolute",
  },
  [`& .${popoverClasses.userMenu}`]: {
    maxHeight: "25vh",
    overflowY: "auto",
  },
}));

const FindingCard = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [users, setUsers] = useState(props.ownerUsers || {});
  const [owners, setFindingsOwners] = useState([]);
  const [removeOwner, setRemoveOwner] = useState(null);
  const [saving, setSaving] = useState(false);
  const [nextWorkflow, setNextWorkflow] = useState(null);
  const [messages, setMessages] = useState(false);

  useEffect(() => {
    if (props.workflows[0] && props.workflows[0].questions) {
      const nextQuestion = props.workflows[0].questions.find(
        (question) => question.answered === false
      );
      if (nextQuestion) {
        setNextWorkflow(nextQuestion.text);
      }
    } else {
      setNextWorkflow(null);
    }
  }, props.workflows);

  useEffect(() => {
    setMessages(props.comments);
  }, [props.comments]);

  useEffect(() => {
    const users = { ...props.ownerUsers };
    const _owners = Object.values(props.owners).reduce((all, x) => {
      x.map((uid) => {
        if (users[uid]) {
          all.push(users[uid]);
          delete users[uid];
        }
      });
      return all;
    }, []);
    setSaving(false);
    setUsers(users);
    setFindingsOwners(_owners);
  }, [props.ownerUsers]);

  const fetchComments = () => {
    setMessages(false);
    props.dispatch(
      MessageActions.getCommentsForFinding({
        findingId: props.id,
      })
    );
  };

  const handleExpandClick = () => {
    if (!expanded) {
      fetchComments();
    }
    setExpanded(!expanded);
  };

  const getText = (textType) => {
    if (!props.constants) return "";

    // this code is to support new areas
    // such as summary dashboard that retrieve
    // constants as a model
    if (Array.isArray(props.constants) && !!props.constants.length)
      return props.constants[0][textType].find((c) => c.id === props[textType])
        .name;

    // this code is to support legacy
    // areas such as responder dashboard
    if (props.constants[textType])
      return props.constants[textType].fromId(props[textType], "");
  };

  const typeText = getText("type");
  const categoryText = getText("category");

  const createdDateTime = moment
    .utc(props.created)
    .tz(moment.tz.guess(true))
    .format("lll z");

  const gotoFinding = () =>
    props.dispatch({
      type: "PAGE",
      payload: {
        orgId: props.orgId,
        toplevel: "reporting",
        secondlevel: "findings",
        id1: props.id,
      },
    });

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (user) => {
    if (user) {
      addOwner(user);
    }
    setAnchorEl(null);
  };

  const addOwner = (user) => {
    const _owners = (owners.concat([user]) || []).map((i) => i.id);
    setSaving(true);
    props.dispatch(
      setOwners({
        findingId: props.id,
        owners: _owners,
        ownerType: props.pageRole || "responder",
      })
    );
  };

  const confirmOwnerRemoval = (user) => {
    if (removeOwner === user.id) {
      const _owners = owners.filter((u) => u.id !== user.id).map((u) => u.id);
      setSaving(true);
      props.dispatch(
        setOwners({
          findingId: props.id,
          owners: _owners,
          ownerType: props.pageRole || "responder",
        })
      );
    } else {
      setRemoveOwner(user.id);
      setTimeout(() => setRemoveOwner(null), 3000);
    }
  };

  return (
    <StyledCard className={cardClasses.root} datacy={`findingCard`}>
      <CardActionArea onClick={gotoFinding}>
        <CardHeader
          avatar={
            <Avatar
              variant="rounded"
              alt={typeText}
              className={cardClasses[`priority${props.priority}`]}
            >
              P{props.priority}
            </Avatar>
          }
          action={<Typography variant="overline">{props.shortId}</Typography>}
          title={
            <div>
              <span className={cardClasses[`flagType${props.type}`]}>
                {typeText}
              </span>
              : {props.name}
              {props.isSample ? (
                <Chip
                  label="Example Finding"
                  color="primary"
                  size="small"
                  variant="outlined"
                  style={{ marginLeft: 12, marginTop: -4 }}
                />
              ) : null}
            </div>
          }
          subheader={
            <span>
              {categoryText} on {createdDateTime}
            </span>
          }
        />
        <CardContent className={cardClasses.mainContent}>
          <Typography gutterBottom variant="h6">
            Analysis
          </Typography>
          <Typography
            variant="body2"
            component="div"
            dangerouslySetInnerHTML={sanitize(props.analysis, {
              html: true,
              safe: true,
            })}
            className={cardClasses.analysisBody}
          />
          <Typography gutterBottom variant="h6">
            Next Workflow Step
          </Typography>
          <Typography
            variant="body2"
            component="div"
            dangerouslySetInnerHTML={sanitize(nextWorkflow, {
              html: true,
              safe: true,
            })}
            className={cardClasses.analysisBody}
          />
        </CardContent>
      </CardActionArea>
      <Divider light />
      {!props.hideComments && (
        <CardActions disableSpacing>
          <div className={cardClasses.cluster}>
            {owners.length > 0 &&
              owners.map((owner) => (
                <Tooltip
                  key={owner.id}
                  title={`Remove Responder: ${owner.first_name} ${owner.last_name}`}
                  className={cardClasses.clusterAvatarItem}
                >
                  <IconButton onClick={() => confirmOwnerRemoval(owner)}>
                    {removeOwner === owner.id ? (
                      <RemoveCircleOutlineIcon
                        color="secondary"
                        style={{ fontSize: 40 }}
                      />
                    ) : (
                      <Avatar
                        alt={`${owner.first_name} ${owner.last_name}`}
                        src={owner.picture}
                        className={cardClasses.clusterAvatar}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              ))}
            <Tooltip
              title="Add Responder"
              className={cardClasses.clusterAvatarItem}
            >
              <IconButton
                onClick={handleClick}
                color="primary"
                datacy={"addResponderButton"}
              >
                <AddCircleIcon />
              </IconButton>
            </Tooltip>
          </div>
          <StyledPopover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            className={popoverClasses.userMenuContainer}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            onClose={() => setAnchorEl(null)}
          >
            <Paper className={popoverClasses.userMenu}>
              <MenuList id={props.id}>
                {Object.values(users).map((user, i) => (
                  <MenuItem
                    key={`${props.id}-user-${i}`}
                    onClick={() => handleClose(user)}
                  >
                    {user.first_name} {user.last_name}
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </StyledPopover>
          <Tooltip title="Messages">
            <IconButton
              className={clsx(cardClasses.expand, {
                [cardClasses.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              datacy={"expandMoreButton"}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
      )}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider light variant="middle" />
        <CardContent>
          <MessageList
            messages={messages}
            dispatch={props.dispatch}
            orgId={props.orgId}
            findingId={props.id}
            currentUser={props.currentUser}
          />
        </CardContent>
      </Collapse>

      {saving && <LinearProgress />}
    </StyledCard>
  );
};

const mapStateToProps = (state, props) => {
  return {
    comments:
      state.message && state.message.getIn
        ? state.message.getIn(["messages", props.id], [])
        : [],
    currentUser: state.session.settings.user.id,
  };
};

export default connect(mapStateToProps)(FindingCard);
