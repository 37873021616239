export const IntegrationTypeConnectwise = "Connectwise";

export const mspIntegrationMetadata = [
  {
    title: "ConnectWise PSA",
    subtitle: "PSA Ticketing",
    imageWidth: 60,
    description: "",
    integrationType: IntegrationTypeConnectwise,
  },
];
