import React, { useEffect, useState } from "react";

import {
  InputLabel,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Autocomplete,
  TextField,
  Box,
} from "@mui/material";
import ForwardIcon from "@mui/icons-material/Forward";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";

import SimpleTable from "views/Components/SimpleTable";
import { StyledAccountMappingView, classes } from "./styles";
import { PSACompanyMappingPSA } from "lib/models/PSACompanyMappingPSA";
import {
  MAPPING_METHODS,
  mappingMethod,
  matchByMappingMethodType,
} from "../../helpers";

const MSPIntegrationAccountMapping = ({
  currentId,
  catchallId,
  companySyncing,
  setCompanySyncing,
  blumiraOrganizationsList,
  setCatchallId,
  companyMapping,
  setCompanyMapping,
}) => {
  const [catchall, setCatchall] = useState(null);
  const [psaCompanyList, setPSACompanyList] = useState([]);
  const [psaCompanyListLoading, setPSACompanyListLoading] = useState();
  const [psaErrorMessage, setPsaErrorMessage] = useState("");
  const [mappedAccountsTableUI, setMappedAccountsTableUI] = useState([]);

  useEffect(() => {
    if (currentId) {
      const queryParams = { id: currentId };

      setPSACompanyListLoading(true);
      const PSAModel = new PSACompanyMappingPSA(queryParams);
      PSAModel.read()
        .then((result) => {
          setPSACompanyList(
            result.companies.map((company) => ({
              ...company,
              id: company.id.toString(),
            }))
          );
          setPSACompanyListLoading(false);
        })
        .catch(() => {
          setPsaErrorMessage("Failed to connect to ConnectWise PSA API.");
        });
    }
  }, [currentId]);

  useEffect(() => {
    if (catchallId && !!psaCompanyList.length) {
      setCatchall(psaCompanyList.find((el) => el.id === catchallId));
    }
  }, [catchallId, psaCompanyList]);

  useEffect(() => {
    // load initial state
    if (
      catchall &&
      Object.keys(catchall).length &&
      !!psaCompanyList.length &&
      !companyMapping.length
    ) {
      setNewCompanyMappings();
    }

    if (
      catchall &&
      Object.keys(catchall).length &&
      !!psaCompanyList.length &&
      companyMapping.length
    ) {
      setUpdateCompanyMappings();
    }
  }, [catchall, psaCompanyList]);

  useEffect(() => {
    if (companyMapping && Object.keys(companyMapping).length) {
      setMappedAccountsTableUI(
        blumiraOrganizationsList.map((row) => {
          const companyMap = companyMapping.find(
            (company) => company.orgId === row.id
          );

          const mappedPsaAccount = psaCompanyList.find(
            (psaCompany) => companyMap?.companyId === psaCompany.id
          );

          return {
            blumiraAccount: row.name,
            id: row.id,
            mappedPsaAccount: companyMap?.mapped ? mappedPsaAccount : catchall,
            mapped: companyMap === undefined ? false : companyMap.mapped,
          };
        })
      );
    }
  }, [companyMapping]);

  useEffect(() => {
    if (companySyncing === MAPPING_METHODS.manual.value) {
      unMapAll();
    } else if (catchall) {
      setUpdateCompanyMappings();
    }

    setCompanySyncing(companySyncing);
  }, [companySyncing]);

  const mappingMethodType = mappingMethod(companySyncing);

  const handleMappingMethodChange = (event) => {
    setCompanySyncing(event.target.value);
  };

  const handleMSPCompanyChange = (rowData, val) => {
    setCompanyMapping(
      companyMapping.map((row) =>
        row.orgId === rowData.id
          ? {
              ...row,
              mapped: val?.id !== catchallId,
              companyId: val?.id,
            }
          : row
      )
    );
  };

  const setNewCompanyMappings = () => {
    setCompanyMapping(
      blumiraOrganizationsList.map((org) => {
        return {
          ...org,
          orgId: org.id,
          companyId: catchall.id,
          mapped: false,
        };
      })
    );
  };

  const setUpdateCompanyMappings = () => {
    setCompanyMapping((prevCompanyMapping) => {
      return blumiraOrganizationsList.map((blumiraOrg) => {
        const companyMap = prevCompanyMapping.find(
          (org) => blumiraOrg.id === org?.orgId
        );

        const checkMappingMatch = matchByMappingMethodType(
          psaCompanyList,
          blumiraOrg.name,
          mappingMethodType,
          catchall
        );

        const updatedCompanyId =
          (companyMap?.mapped === undefined &&
            companyMap?.companyId !== catchall?.id) ||
          companyMap?.mapped
            ? companyMap?.companyId
            : checkMappingMatch?.id;

        return {
          ...companyMap,
          companyId: updatedCompanyId,
          mapped: updatedCompanyId !== catchall?.id,
        };
      });
    });
  };

  const unMapAll = () => {
    setCompanyMapping((prevCompanyMapping) => {
      return prevCompanyMapping.map((org) => {
        return {
          ...org,
          companyId: catchall?.id,
          mapped: false,
        };
      });
    });
  };

  const mappingTitle = () => {
    if (companySyncing === MAPPING_METHODS.companyId.value) {
      return "ConnectWise PSA Company ID";
    } else if (companySyncing === MAPPING_METHODS.companyName.value) {
      return "ConnectWise PSA Company Name";
    }

    return "ConnectWise PSA Company ID or Name";
  };

  const manageMSPAutocomplete = (row) => {
    const updateCatchallName =
      row.mappedPsaAccount?.id === catchall.id
        ? {
            ...row.mappedPsaAccount,
            identifier: `Catchall (${row.mappedPsaAccount.identifier})`,
            name: `Catchall (${row.mappedPsaAccount.name})`,
          }
        : row.mappedPsaAccount;

    const mappingMethodTypeDisplay =
      companySyncing === MAPPING_METHODS.companyName.value ? "Name" : "ID";

    return (
      <Autocomplete
        id={`${row.blumiraAccount}-autocomplete`}
        options={psaCompanyList.sort((a, b) => {
          if (a.id === catchall.id) return -1;
          if (b.id === catchall.id) return 1;
          return 0;
        })}
        autoHighlight
        disableClearable
        loading={psaCompanyListLoading}
        getOptionLabel={(option) =>
          companySyncing === MAPPING_METHODS.companyName.value
            ? option.name
            : option.identifier
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionKey={(option) => option.id}
        value={updateCatchallName}
        onChange={(_, val) => handleMSPCompanyChange(row, val)}
        renderInput={(params) => <TextField {...params} />}
        groupBy={(option) =>
          option.id === catchall.id
            ? `Catchall Company ${mappingMethodTypeDisplay}`
            : `Company ${mappingMethodTypeDisplay}`
        }
      />
    );
  };

  const mappedChip = (
    <span className={`${classes.chip} ${classes.mappedChip}`}>
      <DoneOutlinedIcon /> Mapped
    </span>
  );

  const unMappedChip = (
    <span className={`${classes.chip} ${classes.unMappedChip}`}>
      <DoNotDisturbOnOutlinedIcon /> Not Mapped
    </span>
  );

  return (
    <StyledAccountMappingView>
      <div className={classes.catchallInput}>
        <InputLabel
          className={classes.catchallLabel}
          htmlFor="catchall-autocomplete-label"
        >
          Catchall
        </InputLabel>
        <Autocomplete
          id="catchall-autocomplete-label"
          sx={{ width: 300 }}
          options={psaCompanyList}
          autoHighlight
          disableClearable
          loading={psaCompanyListLoading}
          getOptionLabel={(option) => option[mappingMethodType]}
          getOptionKey={(option) => option.id}
          value={catchall}
          onChange={(_, newValue) => {
            setCatchallId(newValue.id);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!psaErrorMessage}
              helperText={psaErrorMessage}
              datacy={"companyCatchallField"}
            />
          )}
          renderOption={(props, option) => {
            const { key, ...optionProps } = props;
            return (
              <Box
                key={key}
                component="li"
                datacy={`companyCatchallItem-${option.name}`}
                {...optionProps}
              >
                {option.name}
              </Box>
            );
          }}
        />
      </div>

      {catchall &&
        !!Object.keys(catchall).length &&
        !!psaCompanyList.length && (
          <>
            <div>
              <FormControl>
                <RadioGroup
                  name="mapping-method"
                  value={companySyncing}
                  onChange={handleMappingMethodChange}
                >
                  <FormControlLabel
                    value={MAPPING_METHODS.companyId.value}
                    control={<Radio datacy={"companyMappingRadioId"} />}
                    label={MAPPING_METHODS.companyId.label}
                  />

                  <FormControlLabel
                    value={MAPPING_METHODS.companyName.value}
                    control={<Radio />}
                    label={MAPPING_METHODS.companyName.label}
                  />

                  <FormControlLabel
                    value={MAPPING_METHODS.manual.value}
                    control={<Radio />}
                    label={MAPPING_METHODS.manual.label}
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div>
              <SimpleTable
                isSearchHidden
                initialOrderBy={["mapped", "asc"]}
                columns={[
                  {
                    field: "mapped",
                    title: "Mapped",
                    icon: (_, val) => (val.mapped ? mappedChip : unMappedChip),
                  },
                  { field: "blumiraAccount", title: "Blumira Account Name" },
                  {
                    field: "mapTo",
                    title: "Map to",
                    sortable: false,
                    renderValue: () => <ForwardIcon />,
                  },
                  {
                    css: { padding: 4 },
                    field: "manage",
                    title: mappingTitle(),
                    sortable: false,
                    renderValue: (_, row) => manageMSPAutocomplete(row),
                  },
                ]}
                data={mappedAccountsTableUI}
              ></SimpleTable>
            </div>
          </>
        )}
    </StyledAccountMappingView>
  );
};

export default MSPIntegrationAccountMapping;
