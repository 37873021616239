import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  Grid,
  Button,
  Tooltip,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";

import Alert from "@mui/material/Alert";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

import { cloudModuleMetadata } from "./constants";
import PropTypes from "prop-types";

import { Integration as IntegrationModel } from "lib/models/Integration";
import moment from "moment-timezone";

import { IntegrationLogo } from "../../Pages/CloudModules/IntegrationLogo";
import { ConfigurationForm } from "./ConfigurationForm";

import Link from "redux-first-router-link";
import { baseContactSalesUrl } from "views/Pages/BillingPageView";
import { StyledDialog, classes } from "./cloudModuleDialogStyles";

const betaCloudConnectorTooltipText =
  "This integration is collection-only, meaning Blumira ingests & stores raw logs collected from this source. No parsing or detections are available yet.";

const CloudModuleDialog = (props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedModel, setSelectedModel] = useState(null);
  const [isAlertVisible, setIsAlertVisible] = useState(true);
  const [isErrorDisplayed, setIsErrorDisplayed] = useState(false);
  const [selectedCloudModule, setSelectedCloudModule] = useState(null);
  const [isConnectingOrSaving, setIsConnectingOrSaving] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [pendingRemoveSelectedModule, setPendingRemoveSelectedModule] =
    useState(false);
  const [hasValidationError, setHasValidationError] = useState(false);
  const [contactSalesUrl, setContactSalesUrl] = useState(baseContactSalesUrl);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (props.editingModel) {
      let module = cloudModuleMetadata.find(
        ({ integrationType }) =>
          integrationType === props.editingModel.integrationType
      );
      setSelectedCloudModule(module);
      setSelectedModel(props.editingModel);
      setCurrentStep(1);
    }
  }, [props.editingModel, props.open]);

  useEffect(() => {
    // connectorIdFromPayload prop is taken from the 'id1' in the page's payload
    // i.e. for a clickthrough event from the summary dashboard
    if (props.connectorIdFromPayload && props.integrations && props.ready) {
      // find the full model to set in state for viewing
      const selectedIntegrationFromPayload = props.integrations.find(
        (integration) => integration.id === props.connectorIdFromPayload
      );

      // if a matching model is found open dialog for viewing
      if (selectedIntegrationFromPayload)
        handleRowClick(selectedIntegrationFromPayload);
    }
  }, [
    props.connectorIdFromPayload,
    props.integrations,
    props.license,
    props.ready,
  ]);

  useEffect(() => {
    if (props.currentUser) {
      const { first_name = "", last_name = "", email = "" } = props.currentUser;
      const contactUrlToSet =
        baseContactSalesUrl +
        `?firstname=${first_name}&lastname=${last_name}&email=${email}`;
      setContactSalesUrl(contactUrlToSet);
    }
  }, [props.currentUser]);

  const resetModal = () => {
    setIsErrorDisplayed(false);
    setIsConnectingOrSaving(false);
    setIsRemoving(false);
    setPendingRemoveSelectedModule(false);
    setTimeout(() => setCurrentStep(0), 200);
  };

  const toggleModal = () => {
    resetModal();
    props.toggleModal();
  };

  const handleNextStep = () => {
    let nextStep = currentStep + 1;
    setCurrentStep(nextStep);
  };

  const handleAddAnotherConnector = () => {
    resetModal();
  };

  const handleInviteTeamMembers = () => {
    props.dispatch({
      type: "PAGE",
      payload: {
        orgId: props.orgId,
        toplevel: "settings",
        secondlevel: "users",
      },
    });
  };

  const handleSelectModule = (module) => {
    setSelectedCloudModule(module);
    setSelectedModel(
      new IntegrationModel({
        integrationName: `${module.title} Cloud Connector ${moment().format(
          "YYYY-MM-DD"
        )}`,
        integrationType: module.integrationType,
        configuration: {},
        secret: {},
      })
    );
    handleNextStep();
  };

  const handleRowClick = (model) => {
    let module = cloudModuleMetadata.find(
      ({ integrationType }) => integrationType === model.integrationType
    );
    setSelectedCloudModule(module);
    setSelectedModel(model);
    setCurrentStep(1);
    toggleModal();
  };

  const handleRemoveButton = () => {
    setIsErrorDisplayed(false);
    setIsConnectingOrSaving(false);
    setIsRemoving(false);
    setPendingRemoveSelectedModule(true);
  };

  const handleConfirmPendingDelete = () => {
    setIsErrorDisplayed(false);
    setIsConnectingOrSaving(false);
    setIsRemoving(true);
    selectedModel
      .delete()
      .then(() => {
        if (props.reload) {
          props.reload(true);
        }
        toggleModal();
        setCurrentStep(0);
      })
      .catch((e) => {
        console.error(e);
        setIsErrorDisplayed(true);
        setIsConnectingOrSaving(false);
        setIsRemoving(false);
      });
  };

  const handleClearPendingDelete = () => {
    setIsErrorDisplayed(false);
    setIsConnectingOrSaving(false);
    setIsRemoving(false);
    setPendingRemoveSelectedModule(false);
  };

  const handleConnect = () => {
    const isUpdating = !!selectedModel.id;
    setIsErrorDisplayed(false);
    setIsConnectingOrSaving(true);
    setIsRemoving(false);
    setErrorMessage("");
    const currentSelectedModelId = isUpdating ? selectedModel.id : null;
    const currentSelectedModelOrgId = isUpdating ? selectedModel.orgId : null;
    const func = isUpdating ? "update" : "create";
    selectedModel[func]()
      .then(() => {
        if (props.reload) {
          props.reload(true);
        }
        if (props.showSuccess) setCurrentStep(2);
        else toggleModal();
      })
      .catch((e) => {
        console.error(e);
        if (isUpdating) {
          selectedModel.set({
            id: currentSelectedModelId,
            orgId: currentSelectedModelOrgId,
          });
        }
        setIsErrorDisplayed(true);
        setIsConnectingOrSaving(false);
        setIsRemoving(false);
        setErrorMessage(e);
      });
  };

  const getConnectButtonText = () => {
    let textToReturn = isConnectingOrSaving ? "Connecting..." : "Connect";
    if (selectedModel && selectedModel.id)
      textToReturn = isConnectingOrSaving ? "Saving..." : "Save Changes";
    return textToReturn;
  };

  const getRemoveButtonText = () => {
    return isRemoving ? "Removing..." : "Remove";
  };

  const currentDialogStepTitle = () => {
    return currentStep === 0
      ? "Available Cloud Connectors"
      : currentStep === 2
      ? "Cloud Connector Setup Successful!"
      : selectedModel?.id
      ? "Edit Cloud Connector"
      : "Add Cloud Connector";
  };

  const handleCancelModal = () => {
    if (selectedModel) {
      selectedModel.reset();
    }
    toggleModal();
  };

  const displayDialogActions = ({ hasValidationError }) => {
    if (currentStep > 0 && pendingRemoveSelectedModule) {
      return (
        <DialogActions className={classes.dialogActions}>
          <div className={classes.dialogActionsBtnContainer}>
            Are you sure you want to remove this cloud connector?
            <br />
            <Button
              datacy={"cloudModuleConfirmRemoveBtn"}
              onClick={handleConfirmPendingDelete}
              disabled={!!isRemoving}
              className={classes.error}
              variant={"text"}
              style={{ marginLeft: 5 }}
            >
              {isRemoving ? "Removing..." : "Confirm"}
            </Button>
            <Button
              onClick={handleClearPendingDelete}
              disabled={!!isRemoving}
              color={"primary"}
              variant={"contained"}
              style={{ marginLeft: 5 }}
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      );
    } else if (currentStep === 1) {
      return (
        <DialogActions className={classes.dialogActions}>
          <div className={classes.dialogActionsBtnContainer}>
            {selectedModel && selectedModel.id && (
              <Button
                datacy={"cloudModuleRemoveBtn"}
                disabled={!!isRemoving}
                onClick={handleRemoveButton}
                className={classes.error}
                variant={"text"}
                style={{ marginLeft: 5 }}
              >
                {getRemoveButtonText()}
              </Button>
            )}
            <Button
              onClick={handleCancelModal}
              color={"primary"}
              variant={"text"}
              style={{ marginLeft: 5 }}
            >
              Cancel
            </Button>
            <Button
              datacy={"cloudModuleConnectBtn"}
              disabled={!!isConnectingOrSaving || hasValidationError}
              onClick={handleConnect}
              color={"primary"}
              variant={"contained"}
              style={{ marginLeft: 5 }}
            >
              {getConnectButtonText()}
            </Button>
          </div>
          {selectedCloudModule && selectedCloudModule.setupGuideLink && (
            <a
              href={selectedCloudModule.setupGuideLink}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              {selectedCloudModule.title} setup guide <OpenInNewIcon />
            </a>
          )}
        </DialogActions>
      );
    }
    return null;
  };

  const CloudButton = (props) => {
    return (
      <Grid item xs={3} className={classes.buttonsGrid}>
        <Button
          variant={"outlined"}
          disabled={props.disabled}
          className={classes.moduleButton}
          datacy={props.module.integrationType}
          onClick={() => {
            // reference paidCta prop so that user can still
            // select to click on the cta link in the card
            if (props.paidCta) return;
            handleSelectModule(props.module);
          }}
        >
          <div className={classes.moduleOptionContainer}>
            <div className={classes.logoCtaContainer}>
              <div className={classes.logoContainer}>
                <IntegrationLogo
                  integrationType={props.module.integrationType}
                  width={props.module.imageWidth || 100}
                  height={
                    props.module.imageWidth
                      ? props.module.imageWidth / 2.166666667
                      : 46
                  }
                />
              </div>
            </div>
            <div className={classes.textContainer}>
              <p className={classes.moduleTitleText}>{props.module.title}</p>
              <p className={classes.moduleSubtitleText}>
                {props.module.subtitle}
              </p>
            </div>
          </div>
        </Button>
        {props.paidCta ? (
          <div className={classes.paidPlanCtaContainer}>
            <p>Available on paid plans</p>
            <div>
              <a
                target={"_blank"}
                rel="noopener noreferrer"
                href={contactSalesUrl}
              >
                Contact sales
                <OpenInNewIcon />
              </a>
            </div>
          </div>
        ) : props.isBeta ? (
          <Tooltip
            title={betaCloudConnectorTooltipText}
            placement={"bottom-start"}
            classes={{ tooltip: classes.tooltip }}
          >
            <div
              className={`${classes.paidPlanCtaContainer} ${classes.betaTooltipCtaContainer}`}
            >
              <p>Beta</p>
              <InfoOutlinedIcon />
            </div>
          </Tooltip>
        ) : props.isNew ? (
          <div
            className={`${classes.paidPlanCtaContainer} ${classes.betaTooltipCtaContainer}`}
          >
            <p>New</p>
            <AutoAwesomeIcon />
          </div>
        ) : null}
      </Grid>
    );
  };

  const getCloudConnectorList = () => {
    // Only show connectors that are generally available OR the the org has the beta license feature enabled for
    return cloudModuleMetadata
      .filter(
        (module) =>
          !module.betaChip || props.license.hasFeature(module?.betaChip)
      )
      .sort((a, b) => (a.title > b.title ? 1 : -1));
  };

  const isModuleInBeta = (module) => {
    {
      /*
      Leaving the following as an example
      for how to show beta flag in UI
      */
    }
    const { integrationType = "" } = module;
    if (integrationType === "replace me for beta module") {
      return true;
    }

    return false;
  };

  const isModuleNew = (module) => {
    const { integrationType = "" } = module;

    if (
      integrationType === "crowdstrike/poll" ||
      integrationType === "mscloud/poll"
    ) {
      return true;
    }

    return false;
  };

  const typeValuesToDisableWhenLimitReached = [
    "sentinelone/poll",
    "duo/poll",
    "mimecast/poll",
    "umbrella/poll",
    "webroot/poll",
    "o365/Initialize",
    "gsuite/poll",
    "sophos/poll",
    "jumpcloud/poll",
    "onelogin/poll",
    "onepass/poll",
    "azhub/poll",
  ];

  const displayDialogContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <Grid
            container
            spacing={2}
            className={classes.moduleOptionsContainer}
            datacy={"cloudConnectorListModal"}
          >
            {getCloudConnectorList().map((module, idx) => {
              // Disable AWS connector on FREE, M365, and CLOUD
              if (
                module.integrationType === "aws/Initialize" &&
                (props.license.isTagged("limited-to-free") ||
                  props.license.isTagged("limited-to-microsoft") ||
                  props.license.isTagged("limited-to-cloud"))
              ) {
                return (
                  <CloudButton
                    key={module.integrationType}
                    module={module}
                    paidCta={true}
                    disabled={true}
                  />
                );
                // Disable Duo, SentinelOne, Mimecast, Umbrella, Webroot, Google and Sophos connectors on FREE and M365 when the 3 connector limit has been reached
              } else if (
                typeValuesToDisableWhenLimitReached.includes(
                  module.integrationType
                ) &&
                (props.license.isTagged("limited-to-free") ||
                  props.license.isTagged("limited-to-microsoft"))
              ) {
                return (
                  <CloudButton
                    key={module.integrationType}
                    module={module}
                    disabled={props.integrations.length === 3}
                    isBeta={isModuleInBeta(module)}
                    isNew={isModuleNew(module)}
                  />
                );
              } else {
                return (
                  <CloudButton
                    module={module}
                    key={module.integrationType}
                    isBeta={isModuleInBeta(module)}
                    isNew={isModuleNew(module)}
                  />
                );
              }
            })}
          </Grid>
        );
      case 1:
        return (
          <div className={classes.moduleFormContainer}>
            {selectedCloudModule && (
              <IntegrationLogo
                integrationType={selectedCloudModule.integrationType}
                width={selectedCloudModule.imageWidth || 100}
                height={
                  selectedCloudModule.imageWidth
                    ? selectedCloudModule.imageWidth / 2.166666667
                    : 46
                }
              />
            )}
            {isAlertVisible &&
              selectedCloudModule &&
              !pendingRemoveSelectedModule &&
              !selectedModel.id &&
              selectedCloudModule.setupGuideLink && (
                <Alert
                  icon={false}
                  className={classes.alert}
                  onClose={() => setIsAlertVisible(false)}
                >
                  Get started by{" "}
                  <a
                    target={"_blank"}
                    rel="noopener noreferrer"
                    href={selectedCloudModule.setupGuideLink}
                  >
                    opening the {selectedCloudModule.title} setup guide.{" "}
                    <OpenInNewIcon />
                  </a>
                </Alert>
              )}
            {isErrorDisplayed &&
              selectedCloudModule &&
              selectedCloudModule.integrationType === "o365/Initialize" && (
                <p className={classes.error}>
                  An error occurred trying to connect to{" "}
                  {selectedCloudModule.title}.<br />
                  Please ensure your entries are correct and try again.
                  <br /> {errorMessage}.<br />
                  For troubleshooting help, click{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://blumira.help/troubleshooting"
                  >
                    here
                  </a>
                </p>
              )}
            {isErrorDisplayed &&
              selectedCloudModule &&
              selectedCloudModule.integrationType !== "o365/Initialize" && (
                <p className={classes.error}>
                  An error occurred trying to connect to{" "}
                  {selectedCloudModule.title}.<br />
                  Please ensure your entries are correct and try again.
                  <br />
                </p>
              )}
            <div className={classes.form}>
              <ConfigurationForm
                model={selectedModel}
                disabled={isConnectingOrSaving || isRemoving}
                onValidation={(validation) =>
                  setHasValidationError(validation.hasValidationError)
                }
                onSubmit={handleConnect}
                schemas={props.integrationSchemas}
                superadmin={props.superadmin}
                selectedCloudModule={selectedCloudModule}
              />
            </div>
          </div>
        );
      case 2:
        return (
          <div className={classes.nextStepContainer}>
            <p>
              Logs should begin flowing in soon, then Blumira will deploy your
              detection rules. This may take up to thirty minutes. While you
              wait...
            </p>
            <div className={classes.nextStepCtaContainer}>
              <Button
                className={classes.nextStepCtaButton}
                color={"primary"}
                variant={"contained"}
                onClick={handleAddAnotherConnector}
              >
                Add another Cloud Connector
              </Button>
              <Button
                className={classes.nextStepCtaButton}
                color={"primary"}
                variant={"outlined"}
                onClick={handleInviteTeamMembers}
              >
                Invite Team Members
              </Button>
            </div>
            <p className={classes.nextStepOrText}>or</p>
            <p className={classes.nextStepText}>
              Go to your Summary Dashboard to get started using Blumira
            </p>
            <Link
              to={{
                type: "PAGE",
                payload: {
                  orgId: props.orgId,
                  toplevel: "dashboard",
                  secondlevel: "summary",
                },
              }}
              className={classes.nextStepLink}
            >
              See Summary Dashboard
            </Link>
          </div>
        );
      default:
        return <span>error: unknown step {currentStep}</span>;
    }
  };

  return (
    <StyledDialog
      open={props.open}
      onClose={handleCancelModal}
      maxWidth={currentStep === 2 ? "sm" : "lg"}
      fullWidth={currentStep === 2}
    >
      <DialogTitle>{currentDialogStepTitle()}</DialogTitle>
      <DialogContent>{displayDialogContent()}</DialogContent>
      {displayDialogActions({ hasValidationError })}
    </StyledDialog>
  );
};

CloudModuleDialog.propTypes = {
  integrations: PropTypes.array,
  integrationSchemas: PropTypes.array,
  ready: PropTypes.bool,
  superadmin: PropTypes.bool,
  showSuccess: PropTypes.bool,
};

CloudModuleDialog.defaultProps = {
  integrations: [],
  integrationSchemas: [],
  ready: false,
  superadmin: false,
  showSuccess: false,
};

const mapStateToProps = (state) => {
  const orgId = state.location.payload.orgId;
  const currentOrg = state.session.settings.userOrgs.find(
    ({ id }) => id === orgId
  );

  return {
    orgId,
    currentOrg,
    currentUser: state?.session?.settings?.user,
  };
};

export default connect(mapStateToProps)(CloudModuleDialog);
