import React, { useEffect, useState } from "react";
import { StyledTicketFlowView, StyledSteps, classes } from "./styles";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { validateEmail } from "views/Components/Form";
import { PSAPriorityMappingPSA } from "lib/models/PSAPriorityMappingPSA";
import { PSATypeMappingPSA } from "lib/models/PSATypeMappingPSA";
import { PSAStatusMappingPSA } from "lib/models/PSAStatusMappingPSA";
import CustomMappingDialog from "./CustomMappingDialog";
import Codex from "@blumira/blu_constants";
import { connect } from "react-redux";

const SYNCING_VALS = {
  default: "Default",
  manual: "Manual",
  auto: "Auto",
};

const validateEmails = (emailList) => {
  const hasEmptyString = emailList.some((value) => value === "");
  if (hasEmptyString) {
    return false;
  }
  return emailList.reduce(
    (accumulator, email) => accumulator && validateEmail(email),
    true
  );
};

const MSPTicketFlow = ({
  boardId,
  defaultEmail,
  setDefaultEmail,
  defaultEmailError,
  setDefaultEmailError,
  ticketSyncing,
  setTicketSyncing,
  filteredTypes,
  blumiraStatuses,
  blumiraPriorities,
  isServiceBoardUpdated,
}) => {
  const SYNCING_TYPES = {
    status: {
      name: "Status",
      id: "statusSyncing",
      mappingName: "statusMappings",
      blumiraMappingName: "blumiraStatus",
      psaMappingName: "psaStatus",
      blumiraMappingList: Object.entries(blumiraStatuses).map(
        ([key, value]) => ({
          id: key,
          label: value,
        })
      ),
    },
    type: {
      name: "Type",
      id: "typeSyncing",
      mappingName: "typeMappings",
      blumiraMappingName: "blumiraType",
      psaMappingName: "psaType",
      blumiraMappingList: Object.entries(filteredTypes).map(([key, value]) => ({
        id: key,
        label: value,
      })),
    },
    priority: {
      name: "Priority",
      id: "prioritySyncing",
      mappingName: "priorityMappings",
      blumiraMappingName: "blumiraPriority",
      psaMappingName: "psaPriority",
      blumiraMappingList: blumiraPriorities,
    },
  };

  const [psaStatusMappingOptions, setPSAStatusMappingOptions] = useState([]);
  const [psaStatusMappingOptionsError, setPSAStatusMappingOptionsError] =
    useState("");

  const [psaTypeMappingOptions, setPSATypeMappingOptions] = useState([]);
  const [psaTypeMappingOptionsError, setPSATypeMappingOptionsError] =
    useState("");

  const [psaPriorityMappingOptions, setPSAPriorityMappingOptions] = useState(
    []
  );
  const [psaPriorityMappingOptionsError, setPSAPriorityMappingOptionsError] =
    useState("");

  useEffect(() => {
    if (boardId && isServiceBoardUpdated && !psaStatusMappingOptions.length) {
      const PSAModel = new PSAStatusMappingPSA({ id: boardId });
      PSAModel.read()
        .then((result) => {
          setPSAStatusMappingOptions(
            result.statuses.map((status) => ({
              ...status,
              id: status.id.toString(),
            }))
          );
          setPSAStatusMappingOptionsError("");
        })
        .catch(() => {
          setPSAStatusMappingOptionsError(
            "Failed to connect to ConnectWise PSA API."
          );
        });
    }

    if (boardId && isServiceBoardUpdated && !psaTypeMappingOptions.length) {
      const PSAModel = new PSATypeMappingPSA({ id: boardId });
      PSAModel.read()
        .then((result) => {
          setPSATypeMappingOptions(
            result.types.map((type) => ({
              ...type,
              id: type.id.toString(),
            }))
          );
          setPSATypeMappingOptionsError("");
        })
        .catch(() => {
          setPSATypeMappingOptionsError(
            "Failed to connect to ConnectWise PSA API."
          );
        });
    }

    if (boardId && isServiceBoardUpdated && !psaPriorityMappingOptions.length) {
      const PSAModel = new PSAPriorityMappingPSA({ id: boardId });
      PSAModel.read()
        .then((result) => {
          setPSAPriorityMappingOptions(
            result.priorities.map((priority) => ({
              ...priority,
              id: priority.id.toString(),
            }))
          );
          setPSAPriorityMappingOptionsError("");
        })
        .catch(() => {
          setPSAPriorityMappingOptionsError(
            "Failed to connect to ConnectWise PSA API."
          );
        });
    }
  }, [boardId, isServiceBoardUpdated]);

  useEffect(() => {
    if (ticketSyncing && Object.keys(ticketSyncing).length === 0) {
      // set defaults
      setTicketSyncing({
        [SYNCING_TYPES.status.id]: SYNCING_VALS.default,
        [SYNCING_TYPES.status.mappingName]: [
          {
            [SYNCING_TYPES.status.psaMappingName]: "",
            [SYNCING_TYPES.status.blumiraMappingName]: "",
          },
        ],
        [SYNCING_TYPES.type.id]: SYNCING_VALS.default,
        [SYNCING_TYPES.type.mappingName]: [
          {
            [SYNCING_TYPES.type.psaMappingName]: "",
            [SYNCING_TYPES.type.blumiraMappingName]: "",
          },
        ],
        [SYNCING_TYPES.priority.id]: SYNCING_VALS.default,
        [SYNCING_TYPES.priority.mappingName]: [
          {
            [SYNCING_TYPES.priority.psaMappingName]: "",
            [SYNCING_TYPES.priority.blumiraMappingName]: "",
          },
        ],
      });
    }
  }, [ticketSyncing]);

  const handleDefaultEmailChange = ({ target }) => {
    setDefaultEmail(target.value);
    setDefaultEmailError(
      !validateEmails(target.value.split(",").map((email) => email.trim()))
    );
  };

  const TicketFlowRadioInput = ({
    syncingVal,
    syncingType,
    mappings,
    psaMappingOptions,
    psaMappingOptionsError,
    setTicketSyncing,
  }) => {
    const mappingModalState = syncingType.id + "MappingDialogOpen";
    let mappingDialogOpen = !!ticketSyncing[mappingModalState];

    const handleRadioChange = ({ target }) => {
      if (target.value === SYNCING_VALS.manual) {
        mappingDialogOpen = true;
      }

      if (target.value === SYNCING_VALS.auto) {
        setSinglePSAMapping(
          mappings[0] && mappings[0][syncingType.psaMappingName]
            ? mappings[0][syncingType.psaMappingName]
            : psaMappingOptions[0].id
        );
      }

      setTicketSyncing((prevState) => ({
        ...prevState,
        [syncingType.id]: target.value,
        [mappingModalState]: mappingDialogOpen,
      }));
    };

    const handleAutoMappingChange = ({ target }) => {
      setSinglePSAMapping(target.value);
    };

    const setSinglePSAMapping = (val) => {
      setTicketSyncing((prevState) => ({
        ...prevState,
        [syncingType.mappingName]: [
          {
            [syncingType.psaMappingName]: val,
            [syncingType.blumiraMappingName]: "",
          },
        ],
      }));
    };

    const handleClickMappingDialogOpen = () => {
      setTicketSyncing((prevState) => ({
        ...prevState,
        [mappingModalState]: true,
      }));
    };

    const handleMappingDialogClose = (val) => {
      setTicketSyncing((prevState) => ({
        ...prevState,
        [mappingModalState]: false,
        [syncingType.mappingName]: val,
      }));
    };

    const radioLabel = `Set ConnectWise PSA ticket ${syncingType.name}`;
    const firstOptionLabel = `Do not set ticket ${syncingType.name} in ConnectWise PSA`;

    return (
      <FormControl>
        <FormLabel
          className={classes.radioLabel}
          id="ticket-flow-radio-buttons-group-label"
        >
          {radioLabel}
        </FormLabel>
        <RadioGroup
          name="ticket-flow"
          aria-labelledby="ticket-flow-radio-buttons-group-label"
          value={syncingVal}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value={SYNCING_VALS.default}
            control={<Radio />}
            label={firstOptionLabel}
            className={classes.formControlLabel}
          />

          <div>
            <FormControlLabel
              value={SYNCING_VALS.manual}
              control={<Radio />}
              label={"Custom mapping"}
              className={classes.formControlLabel}
            />
            <div className={classes.radioChild}>
              <Button
                variant={"outlined"}
                disabled={syncingVal !== SYNCING_VALS.manual}
                onClick={handleClickMappingDialogOpen}
              >
                Configure
              </Button>
              <CustomMappingDialog
                open={mappingDialogOpen}
                onClose={handleMappingDialogClose}
                mappings={mappings}
                syncingType={syncingType}
                psaMappingOptions={psaMappingOptions}
                psaMappingOptionsError={psaMappingOptionsError}
              />
            </div>
          </div>

          <div>
            <FormControlLabel
              value={SYNCING_VALS.auto}
              control={
                <Radio datacy={`psaMappingOptions-${syncingType.name}`} />
              }
              label={`Set a single ${syncingType.name} for all Blumira findings`}
              className={classes.formControlLabel}
            />
            {!!psaMappingOptions?.length && (
              <div className={classes.radioChild}>
                <FormControl
                  sx={{ width: 300 }}
                  disabled={syncingVal !== SYNCING_VALS.auto}
                >
                  <Select
                    aria-label={`Set a single ${syncingType.name} for all Blumira findings`}
                    error={psaMappingOptionsError.length > 0}
                    value={
                      mappings[0] && mappings[0][syncingType.psaMappingName]
                        ? mappings[0][syncingType.psaMappingName]
                        : psaMappingOptions[0].id
                    }
                    onChange={handleAutoMappingChange}
                  >
                    {psaMappingOptions.map((mappingOption) => (
                      <MenuItem
                        key={`${syncingVal}-${mappingOption.id}`}
                        value={mappingOption.id}
                      >
                        {mappingOption.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{psaMappingOptionsError}</FormHelperText>
                </FormControl>
              </div>
            )}
          </div>
        </RadioGroup>
      </FormControl>
    );
  };

  return (
    <StyledTicketFlowView>
      <StyledSteps>
        <li>
          <Typography className="step-label">
            Step 1 – Set failsafe email
          </Typography>
          <Typography className={classes.defaultEmailText}>
            In the event of any API connection failure we want to make sure that
            any generated findings still make it to you.
          </Typography>
          <TextField
            inputProps={{ "aria-label": "Set failsafe email" }}
            type="text"
            value={defaultEmail}
            className={classes.defaultEmailInput}
            onChange={handleDefaultEmailChange}
            helperText={"Separate multiple email addresses with a comma"}
            error={defaultEmailError}
            required
            FormHelperTextProps={{
              sx: { marginLeft: 0 },
            }}
            datacy={"fallbackEmailField"}
          />
        </li>
        <li>
          <Typography className="step-label">
            Step 2 – Configure ConnectWise PSA tickets from Blumira
          </Typography>

          {!!Object.keys(ticketSyncing).length > 0 && (
            <ul className={classes.configureTicketsList}>
              <li>
                {!!psaStatusMappingOptions.length && (
                  <TicketFlowRadioInput
                    syncingVal={ticketSyncing.statusSyncing}
                    syncingType={SYNCING_TYPES.status}
                    mappings={ticketSyncing.statusMappings}
                    psaMappingOptions={psaStatusMappingOptions}
                    psaMappingOptionsError={psaStatusMappingOptionsError}
                    setTicketSyncing={setTicketSyncing}
                  />
                )}
              </li>
              <li>
                {!!psaTypeMappingOptions.length && (
                  <TicketFlowRadioInput
                    syncingVal={ticketSyncing.typeSyncing}
                    syncingType={SYNCING_TYPES.type}
                    mappings={ticketSyncing.typeMappings}
                    psaMappingOptions={psaTypeMappingOptions}
                    psaMappingOptionsError={psaTypeMappingOptionsError}
                    setTicketSyncing={setTicketSyncing}
                  />
                )}
              </li>
              <li>
                {!!psaPriorityMappingOptions.length && (
                  <TicketFlowRadioInput
                    syncingVal={ticketSyncing.prioritySyncing}
                    syncingType={SYNCING_TYPES.priority}
                    mappings={ticketSyncing.priorityMappings}
                    psaMappingOptions={psaPriorityMappingOptions}
                    psaMappingOptionsError={psaPriorityMappingOptionsError}
                    setTicketSyncing={setTicketSyncing}
                  />
                )}
              </li>
            </ul>
          )}
        </li>
      </StyledSteps>
    </StyledTicketFlowView>
  );
};

const mapStateToProps = () => ({
  blumiraStatuses: Codex.language.models.finding.status,
  blumiraPriorities: [
    { id: "1", label: "1" },
    { id: "2", label: "2" },
    { id: "3", label: "3" },
  ],
});

export default connect(mapStateToProps)(MSPTicketFlow);
